import View from "./View";

class ModalAttributionImageView extends View {
  _parentElement = document.querySelector(
    ".bird__information__image__attribution"
  );
  image = document.querySelector(".bird__information__image");

  _generateMarkup(bird) {
    return `
        <div class="image__attribution">
            <div class="bird__information__attribution-owner">${bird.image.attribution.artist.replaceAll(/\\/g, "")} ${
              bird.image.attribution.credit
                ? bird.image.attribution.credit.replaceAll(/\\/g, "")
                : ""
            }</div>
            <div class="bird__information__attribution-licence">
                <a href="${bird.image.attribution.licenseUrl}">${bird.image.attribution.license}</a>
            </div>
        </div>
    `;
  }
}

export default new ModalAttributionImageView();
