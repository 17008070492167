import View from "./View";
import icons from "url:../../images/icons.svg";
import image from "url:../../images/no_image.png";

class birdsView extends View {
  _parentElement = document.querySelector(".birds__list");
  _errorMessage = "No birds found ☹";
  _message = "";
  _container = document.querySelector(".birds__container");
  _title = document.querySelector(".section__title-description");
  _favouritesInstruction = document.querySelector(".worm__instruction");
  _footer = document.querySelector(".footer");
  // _country = document.getElementById('country')

  setTitle(title) {
    this._title.textContent = title;
  }

  setSearch() {
    window.location.hash = "search";
    this.setTitle("Search Results");
  }

  // setContainerBottomMargin(val) {
  //   this._container.style.marginBottom = `${val}px`;
  // }

  setFooterMargin(val) {
    const orient = this.getWindowOrientation();

    if (orient === "portrait-primary")
      this._footer.style.marginBottom = `${val}px`;

    if (orient === "landscape-primary") this._footer.style.marginBottom = 0;
  }

  scrollUp() {
    this._container.scrollTop = 0;
  }

  animateHeight = function () {
    const birds = document.querySelectorAll(".bird");
    if (!birds) return;

    birds.forEach((bird) => {
      // return;
      if (window.innerHeight >= window.innerWidth)
        bird.style.maxHeight = "15rem";
      if (window.innerHeight < window.innerWidth)
        bird.style.maxHeight = "20rem";
    });
  };

  setActiveBgColour(id) {
    if (id) {
      const bird = document.getElementById(id);
      if (bird) bird.style.backgroundColor = "var(--colour-bird)";
    }
  }

  resetBgColour(id) {
    const element = document.getElementById(id);
    if (element) element.style.backgroundColor = "var(--colour-background2)";
  }

  _addHandlerClickAnimation(bird) {
    ["touchstart", "touchend", "mousedown", "mouseup"].forEach((ev) => {
      bird.addEventListener(
        ev,
        (e) => {
          const birdImage = e.target.closest(".bird__image__container");
          if (birdImage) {
            return;
          }

          const worm = e.target.closest(".worm");
          if (worm) {
            return;
          }

          this._toggleBirdDepress(bird.id);
        },
        {
          passive: true,
        }
      );
    });
  }

  _toggleBirdDepress(birdId) {
    document.getElementById(birdId).classList.toggle("border__none");
  }

  showSoundwave(birdId) {
    // document.getElementById(`soundwave-${birdId}`).classList.remove("hidden");
    // document.getElementById(`soundwave-${birdId}`).style.height = "64px";
    document.getElementById(`recordings-${birdId}`).classList.add("hidden");
  }

  hideSoundwave(birdId) {
    document.getElementById(`soundwave-${birdId}`).classList.add("hidden");
    document.getElementById(`soundwave-${birdId}`).style.height = "0";
    document.getElementById(`recordings-${birdId}`).classList.remove("hidden");
  }

  hideAllSoundwaves() {
    document.querySelectorAll(".soundwave_container").forEach((wave) => {
      wave.classList.add("hidden");
      wave.style.height = "0";
    });
    document.querySelectorAll(".bird__recordings").forEach((el) => {
      el.classList.remove("hidden");
    });
  }

  toggleWorm(birdId, favourites) {
    // console.log(birdId);
    // check if already saved to favourites
    const isFavourite = favourites.some((el) => el === birdId);

    const worm = document.getElementById(`worm-${birdId}`);
    worm
      .querySelector(".worm__svg")
      .setAttribute(
        "href",
        `${icons}#icon-worm-${isFavourite ? "white" : "colour"}`
      );
  }

  addHandlerBird(handler) {
    const birds = document.querySelectorAll(".bird");
    if (!birds) return;

    // choose outcome
    birds.forEach(function (bird) {
      bird.addEventListener("click", function (e) {
        // add favourite

        const worm = e.target.closest(".worm");
        if (worm) {
          handler(bird.id, "favourite");
          return;
        }

        // image info
        const image = e.target.closest(".bird__image__container");
        if (image) {
          handler(bird.id, "image");
          return;
        }

        // if none of the others, add audio
        handler(bird.id, "audio");
      });
    });

    // click animation
    birds.forEach(this._addHandlerClickAnimation.bind(this));
  }

  hideBird(id) {
    const bird = document.getElementById(id);
    bird.style.maxHeight = 0;
    bird.style.border = 0;
    bird.style.marginBottom = 0;
    bird.style.padding = 0;
  }

  hideFavouritesInstruction() {
    this._favouritesInstruction.classList.add("hidden");
  }

  showFavouritesInstruction() {
    this._favouritesInstruction.classList.remove("hidden");
  }

  _generateMarkup() {
    let count = 0;

    return this.data
      .map((el) => {
        count++;
        return this._generateMarkupBird(el, count);
      })
      .join("");
  }

  _generateSpinner() {
    this.clear();
    const markup = `<div class="spinner spinner__section">
                      <svg class="spinner_svg">
                        <use href="${icons}#icon-spinner"></use>
                      </svg>
                    </div>`;
    this._parentElement.insertAdjacentHTML("afterbegin", markup);
  }

  _generateMarkupBird(data, count) {
    // console.log(data);
    // console.log(data);

    return `<li class="bird" id="${data.id}">
            <div class="bird__image__container" id="bird-image-container-${
              data.id
            }">
              <div class="spinner">
                <svg class="spinner_svg">
                 <use href="${icons}#icon-spinner"></use>
                </svg>
              </div>
              
              <img
                class="bird__image hidden"
                data-src="${data.image.thumb ? data.image.thumb : image}" 
                alt="bird image"
                id="${count}"
              />
              <svg class="info__icon">
                <use href="${icons}#icon-info"></use>
              </svg>
            </div>

            <div class="bird__info__container">
              <div class="bird__title_container">
                <div class="bird__info__title">
                <h2>${data.name}</h2>
                <p class="scientific__name">${data.scientific}</p>
              </div>
                <div class="worm__container">
                  <svg class="worm" id="worm-${data.id}">
                    <use
                      class="worm__svg"
                      href="${icons}#icon-worm-${
                        data.favourite ? "colour" : "white"
                      }"
                    ></use>
                  </svg>
                </div>
              </div>

              <div class="bird__details__container">
               <p><b>Region:</b> ${data.location.commonRegionCodes.join(", ")}</p>
              </div>

              <div class="bird__recordings__container">  
                <div class="bird__recordings" id="recordings-${data.id}">
                  <p class="audio__text">CLICK TO PLAY</p>
                  <div class="audio__image__container">
                    <svg class="audio__image" id="audio-${data.id}">
                      <use
                        class="audio__image__svg"
                        href="${icons}#icon-audio-play2"
                      ></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </li>
    `;
  }
}

export default new birdsView();
