import View from "./View";
import icons from "url:../../images/icons.svg";
import placeholder from "url:../../images/no_image.png";

class birdImageView extends View {
  _parentElement = document.querySelectorAll(".bird__image__container");
  _errorMessage = "No image found";
  _message = "";

  // Lazy loader
  addHandlerImageLoad(handler) {
    const container = document.querySelector(".birds__list");
    const images = document.querySelectorAll(".bird__image");

    let minId = null;
    let maxId = null;
    let debounceTimeout = null;

    // changes to be applied
    const applyChanges = function () {
      const items = document.querySelectorAll(".bird__image");
      // perform action on elements with Id between min and max
      items.forEach((el) => {
        const id = parseInt(el.id);

        if (el.src) return;
        if (id >= minId && id <= maxId) {
          const imageId = el.parentElement.id;
          const birdId = el.parentElement.parentElement.id;

          handler(birdId, imageId);
        }
      });

      //
      minId = null;
      maxId = null;
    };

    // Check id's of images passing through intersection
    const reportIntersection = function (entries, observer) {
      clearTimeout(debounceTimeout);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const entryId = parseInt(entry.target.id);
          if (minId === null || maxId === null) {
            minId = entryId;
            maxId = entryId;
          } else {
            minId = Math.min(minId, entryId);
            maxId = Math.max(maxId, entryId);
          }
        }
      });
      debounceTimeout = setTimeout(applyChanges, 500);
    };

    const imgObserver = new IntersectionObserver(reportIntersection, container);

    images.forEach((img) => imgObserver.observe(img));
  }

  renderImage = function (data, id, disabled) {
    // console.log(data, id);
    // document.getElementById(id).innerHTML = this._generateMarkup(data);
    // console.log(data);
    // console.log(disabled);
    // const thumb = data[0].image.thumb;
    // console.log(thumb);
    // let source;

    // if (disabled === "on") {
    //   source = placeholder;
    // } else if (thumb) {
    //   source = thumb;
    // } else {
    //   source = placeholder;
    // }

    // console.log(data[0].image);
    // console.log(data[0].image.thumb);

    const source =
      data[0].image.thumb !== "" && disabled === "off"
        ? data[0].image.thumb
        : placeholder;

    // console.log(source);
    const image = document.getElementById(id).querySelector(".bird__image");
    const spinner = document.getElementById(id).querySelector(".spinner");

    image.src = source;

    image.addEventListener("load", () => {
      spinner.classList.add("hidden");
      image.classList.remove("hidden");
    });
  };
}

export default new birdImageView();
