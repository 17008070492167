import View from "./View";

class contactView extends View {
  _parentElement = document.querySelector(".contact");

  show() {
    this._parentElement.classList.remove("hidden");
  }

  hide() {
    this._parentElement.classList.add("hidden");
  }
}

export default new contactView();
