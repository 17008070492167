import View from "./View";
import icons from "url:../../images/icons.svg";

class audioTrackView extends View {
  _parentElement = document.querySelector(".audio__selector__track-track");
  _errorMessage = "";
  btnNext = document.querySelector(".btn__track-right");
  btnPrev = document.querySelector(".btn__track-left");

  addHandlerNextTrack(handler) {
    this.btnNext.addEventListener("click", this.nextTrack.bind(this, handler));
  }
  addHandlerPrevTrack(handler) {
    this.btnPrev.addEventListener("click", this.prevTrack.bind(this, handler));
  }

  nextTrack(handler) {
    const curTrack = Number(
      document.querySelector(".audio__track").textContent
    );
    const available = Number(
      document.querySelector(".audio__available__tracks").textContent
    );

    if (curTrack < available) handler(`${curTrack}`);
  }

  prevTrack(handler) {
    const curTrack = Number(
      document.querySelector(".audio__track").textContent
    );

    if (curTrack > 1) handler(`${curTrack - 2}`);
  }

  _generateMarkup() {
    return `<p class="audio__track">${
      Number(this.data.selectAudioTrackIndex) + 1
    }</p>
    <p>/</p>
    <p class="audio__available__tracks">${this.data.availableAudioTracks}</p>`;
  }
}

export default new audioTrackView();
