import View from "./View";

class ModalAttributionAudioView extends View {
  _parentElement = document.querySelector(
    ".bird__information__audio__attribution"
  );

  _generateMarkup(data) {
    if (!data.activeBirdId) {
      return "";
    }

    const recordings = data.birds.filter(
      (birds) => birds.id === data.activeBirdId
    )[0].recordings;
    const recordingId =
      data.loadedRecordings[
        data.availableAudioTypes[data.activeAudioTypeIndex]
      ][data.activeAudioTrackIndex].id;
    const activeRecording = recordings[
      data.availableAudioTypes[data.activeAudioTypeIndex]
    ].filter((rec) => rec.id === recordingId)[0];
    // console.log(data.activeAudioTypeIndex)
    // console.log(data.activeAudioTrackIndex)
    // console.log(activeRecording)
    const url = activeRecording.url;

    // console.log(url)
    return `<div class="audio__embed">
      <p>The audio currently playing can be found below. Contact the copyright owner if you wish to use this audio.</p>
      <iframe src='https:${url}/embed?simple=1' scrolling='no' frameborder='0' width='340' height='115'></iframe>
      </div>`;
  }
}

export default new ModalAttributionAudioView();
