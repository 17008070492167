import View from "./View";
import icons from "url:../../images/icons.svg";

class audioTypeView extends View {
  _parentElement = document.querySelector(".audio__selector__type-type");
  _errorMessage = "";
  btnNext = document.querySelector(".btn__type-right");
  btnPrev = document.querySelector(".btn__type-left");
  curSlide = 0;
  portrait = window.matchMedia("(orientation: portrait)");

  addHandlerOrientation(handler) {
    this.portrait.addEventListener("change", handler);
  }

  setCurrentSlide(slide) {
    this.curSlide = slide;
  }

  addHandlerNextType(handler) {
    this.btnNext.addEventListener("click", this.nextSlide.bind(this, handler));
  }
  addHandlerPrevType(handler) {
    this.btnPrev.addEventListener("click", this.prevSlide.bind(this, handler));
  }

  goToSlide(slide) {
    const slides = document.querySelectorAll(".audio__type");
    slides.forEach((s) => (s.style.transform = `translateX(${-100 * slide}%)`));
  }

  // Next slide
  nextSlide(handler) {
    const slide = this.curSlide;
    const slides = document.querySelectorAll(".audio__type");
    const maxSlide = slides.length;
    if (this.curSlide !== maxSlide - 1) {
      this.setCurrentSlide(slide + 1);
      this.goToSlide(this.curSlide);
      handler(this.curSlide);
    }
  }

  prevSlide(handler) {
    const slide = this.curSlide;
    if (this.curSlide !== 0) {
      this.setCurrentSlide(slide - 1);
      this.goToSlide(this.curSlide);
      handler(this.curSlide);
    }
  }

  setContainerWidth(data) {
    if (!data) return;
    const parentWidth = this._parentElement.parentElement.offsetWidth;
    const calcWidth = parentWidth * data.length;
    this._parentElement.style.width = `${calcWidth}px`;
    this._parentElement.style.justifyContent = "flex-start";
  }

  _generateMarkup() {
    this.setContainerWidth(this.data);
    return this.data
      .map((type) => {
        return `<p class="audio__type">${type}</p>`;
      })
      .join("");
  }
}

export default new audioTypeView();
