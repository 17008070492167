export default class View {
  render(data) {
    if (!data || (Array.isArray(data) && data.length === 0))
      return this.renderError();

    this.data = data;
    const markup = this._generateMarkup(this.data);

    this.clear();

    this._parentElement.insertAdjacentHTML("afterbegin", markup);
  }

  hide() {
    this._parentElement.classList.add("hidden");
  }

  show() {
    this._parentElement.classList.remove("hidden");
  }

  clear() {
    this._parentElement.innerHTML = "";
  }

  getWindowOrientation() {
    if (window.innerHeight >= window.innerWidth) {
      return "portrait-primary";
    } else {
      return "landscape-primary";
    }
    // return window.screen.orientation.type;
  }

  update(data) {
    this.data = data;

    const newMarkup = this._generateMarkup(data);

    const newDOM = document.createRange().createContextualFragment(newMarkup);
    const newElements = Array.from(newDOM.querySelectorAll("*"));
    const curElements = Array.from(this._parentElement.querySelectorAll("*"));

    newElements.forEach((newEl, i) => {
      const curEl = curElements[i];
      // console.log(curEl, newEl.isEqualNode(curEl));
      // console.log(newEl);
      // Updates changed TEXT
      if (
        !newEl.isEqualNode(curEl) &&
        newEl.firstChild?.nodeValue.trim() !== ""
      ) {
        // console.log("🛑", newEl.firstChild.nodeValue.trim());
        curEl.textContent = newEl.textContent;
      }

      // Update changed ATTRIBUTES
      if (!newEl.isEqualNode(curEl)) {
        // console.log(Array.from(newEl.attributes));
        Array.from(newEl.attributes).forEach((attr) =>
          curEl.setAttribute(attr.name, attr.value)
        );
      }
    });
  }

  renderError(message = this._errorMessage) {
    const markup = `
    <div class="error__container">
    <p class="error__message">${message}</p>
    </div>`;
    this.clear();
    this._parentElement.insertAdjacentHTML("afterbegin", markup);
  }

  renderMessage(message = this._message) {
    const markup = `<p>${message}</p>`;
    this.clear();
    this._parentElement.insertAdjacentHTML("afterbegin", markup);
  }
}
