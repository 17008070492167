import View from "./View";

class searchFilterView extends View {
  _parentElement = document.querySelector(".search__filter");
  _overlay = document.querySelector(".overlay");
  _filterBtn = document.querySelector(".filter__btn");
  _filterList = document.querySelector(".filter__list");
  _filterTitleBirds = document.querySelector(".filter__title__birdsection");
  _filterTitleDesc = document.querySelector(".filter__title-description");

  setFilterTitle(title) {
    this._filterTitleDesc.textContent = title;
  }

  hideFilterTitle() {
    this._filterTitleBirds.classList.add("hidden");
  }

  showFilterTitle() {
    this._filterTitleBirds.classList.remove("hidden");
  }

  addHandlerOverlay(handler) {
    this._overlay.addEventListener("click", function (e) {
      handler();
    });
  }

  addHandlerFilterBtn(handler) {
    this._filterBtn.addEventListener("click", function (e) {
      e.preventDefault();
      handler();
    });
  }

  hideFilterList() {
    this._overlay.classList.add("hidden");
    this._filterList.classList.add("hidden");
  }

  showFilterList() {
    this._overlay.classList.remove("hidden");
    this._filterList.classList.remove("hidden");
  }

  addHandlerFilterList(handler) {
    this._parentElement.addEventListener("click", function (e) {
      const item = e.target.closest(".filter__item");

      if (!item) return;

      const value = item.dataset.value;
      const title = item.textContent;

      handler(value, title);
    });
  }
}

export default new searchFilterView();
