import View from "./View";

class searchView extends View {
  _parentElement = document.querySelector(".search");
  _errorMessage = "No birds found";
  _flag = document.getElementById("flag");

  setFilterEmojiFlag(countryCode = "") {
    if (!countryCode) {
      this._flag.textContent = "";
      return;
    }

    const codePoints = countryCode
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt());
    // console.log(codePoints);
    // console.log(...codePoints);
    this._flag.textContent = String.fromCodePoint(...codePoints);
  }

  setFilterEmojiGlobe(regionCode = "") {
    let codePoint;

    if (regionCode === "NA" || regionCode === "MA" || regionCode === "SA")
      codePoint = "0x1F30E";

    if (regionCode === "PAL" || regionCode === "AF" || regionCode === "ALL")
      codePoint = "0x1F30D";

    if (regionCode === "OR" || regionCode === "AU") codePoint = "0x1F30F";

    if (regionCode === "AN") codePoint = "0x1F9CA";

    this._flag.textContent = String.fromCodePoint(codePoint);
  }

  getQuery() {
    const query = this._parentElement.querySelector(".search__field").value;
    this.clearSearch();
    return query;
  }

  addHandlerSearch(handler) {
    this._parentElement.addEventListener("submit", function (e) {
      // console.log("submit");
      e.preventDefault();
      handler();
    });
  }

  clearSearch() {
    this._parentElement.querySelector(".search__field").value = "";
  }
}

export default new searchView();
