export const COMMON_BIRDS = [
  {
    name: `Canada Goose`,
    id: `canada_goose`,
    scientific: `Branta canadensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [
      "United States",
      "Canada",
      "New Zealand",
      "United Kingdom",
    ],
    commonRegionCodes: ["NA", "PAL"],
    commonAll: false,
  },
  {
    name: `Corn Crake`,
    id: `corn_crake`,
    scientific: `Crex crex`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Snow Goose`,
    id: `snow_goose`,
    scientific: `Anser caerulescens`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Greylag Goose`,
    id: `greylag_goose`,
    scientific: `Anser anser`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Netherlands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black Swan`,
    id: `black_swan`,
    scientific: `Cygnus atratus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Mute Swan`,
    id: `mute_swan`,
    scientific: `Cygnus olor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Poland",
      "Netherlands",
      "Finland",
      "Denmark",
      "Czech Republic",
      "United Kingdom",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Spur-winged Goose`,
    id: `spur-winged_goose`,
    scientific: `Plectropterus gambensis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Egyptian Goose`,
    id: `egyptian_goose`,
    scientific: `Alopochen aegyptiaca`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "South Africa"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Common Shelduck`,
    id: `common_shelduck`,
    scientific: `Tadorna tadorna`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australian Shelduck`,
    id: `australian_shelduck`,
    scientific: `Tadorna tadornoides`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Maned Duck`,
    id: `maned_duck`,
    scientific: `Chenonetta jubata`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Northern Shoveler`,
    id: `northern_shoveler`,
    scientific: `Spatula clypeata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Eurasian Wigeon`,
    id: `eurasian_wigeon`,
    scientific: `Mareca penelope`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `American Wigeon`,
    id: `american_wigeon`,
    scientific: `Mareca americana`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Yellow-billed Duck`,
    id: `yellow-billed_duck`,
    scientific: `Anas undulata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Pacific Black Duck`,
    id: `pacific_black_duck`,
    scientific: `Anas superciliosa`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Indian Spot-billed Duck`,
    id: `indian_spot-billed_duck`,
    scientific: `Anas poecilorhyncha`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eastern Spot-billed Duck`,
    id: `eastern_spot-billed_duck`,
    scientific: `Anas zonorhyncha`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Mallard`,
    id: `mallard`,
    scientific: `Anas platyrhynchos`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: [
      "United States",
      "Canada",
      "United Kingdom",
      "Germany",
      "New Zealand",
      "Poland",
      "Sweden",
      "Norway",
      "Netherlands",
      "Czech Republic",
      "Iceland",
    ],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `American Black Duck`,
    id: `american_black_duck`,
    scientific: `Anas rubripes`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Northern Pintail`,
    id: `northern_pintail`,
    scientific: `Anas acuta`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Teal`,
    id: `eurasian_teal`,
    scientific: `Anas crecca`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "NA"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-billed Teal`,
    id: `yellow-billed_teal`,
    scientific: `Anas flavirostris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Tufted Duck`,
    id: `tufted_duck`,
    scientific: `Aythya fuligula`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Netherlands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Eider`,
    id: `common_eider`,
    scientific: `Somateria mollissima`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Harlequin Duck`,
    id: `harlequin_duck`,
    scientific: `Histrionicus histrionicus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Scoter`,
    id: `common_scoter`,
    scientific: `Melanitta nigra`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Bufflehead`,
    id: `bufflehead`,
    scientific: `Bucephala albeola`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Common Goldeneye`,
    id: `common_goldeneye`,
    scientific: `Bucephala clangula`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Common Merganser`,
    id: `common_merganser`,
    scientific: `Mergus merganser`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-breasted Merganser`,
    id: `red-breasted_merganser`,
    scientific: `Mergus serrator`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australian Brushturkey`,
    id: `australian_brushturkey`,
    scientific: `Alectura lathami`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Melanesian Megapode`,
    id: `melanesian_megapode`,
    scientific: `Megapodius eremita`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Plain Chachalaca`,
    id: `plain_chachalaca`,
    scientific: `Ortalis vetula`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Helmeted Guineafowl`,
    id: `helmeted_guineafowl`,
    scientific: `Numida meleagris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `California Quail`,
    id: `california_quail`,
    scientific: `Callipepla californica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Pheasant`,
    id: `common_pheasant`,
    scientific: `Phasianus colchicus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: [
      "China",
      "Ireland",
      "Poland",
      "Netherlands",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Croatia",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Green Pheasant`,
    id: `green_pheasant`,
    scientific: `Phasianus versicolor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Indian Peafowl`,
    id: `indian_peafowl`,
    scientific: `Pavo cristatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Chinese Bamboo Partridge`,
    id: `chinese_bamboo_partridge`,
    scientific: `Bambusicola thoracicus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red Junglefowl`,
    id: `red_junglefowl`,
    scientific: `Gallus gallus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Quail`,
    id: `japanese_quail`,
    scientific: `Coturnix japonica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-legged Partridge`,
    id: `red-legged_partridge`,
    scientific: `Alectoris rufa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["France"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Nightjar`,
    id: `european_nightjar`,
    scientific: `Caprimulgus europaeus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Large-tailed Nightjar`,
    id: `large-tailed_nightjar`,
    scientific: `Caprimulgus macrurus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Madagascar Nightjar`,
    id: `madagascar_nightjar`,
    scientific: `Caprimulgus madagascariensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Papuan Frogmouth`,
    id: `papuan_frogmouth`,
    scientific: `Podargus papuensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Tawny Frogmouth`,
    id: `tawny_frogmouth`,
    scientific: `Podargus strigoides`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Australian Owlet-nightjar`,
    id: `australian_owlet-nightjar`,
    scientific: `Aegotheles cristatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Moustached Treeswift`,
    id: `moustached_treeswift`,
    scientific: `Hemiprocne mystacea`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-collared Swift`,
    id: `white-collared_swift`,
    scientific: `Streptoprocne zonaris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA", "NA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `African Palm Swift`,
    id: `african_palm_swift`,
    scientific: `Cypsiurus parvus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Asian Palm Swift`,
    id: `asian_palm_swift`,
    scientific: `Cypsiurus balasiensis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Common Swift`,
    id: `common_swift`,
    scientific: `Apus apus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Spain",
      "Ireland",
      "Greece",
      "Poland",
      "Russia",
      "France",
      "Netherlands",
      "Italy",
      "Switzerland",
      "Denmark",
      "Belgium",
      "Belgium",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `White-necked Jacobin`,
    id: `white-necked_jacobin`,
    scientific: `Florisuga mellivora`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Ruby-throated Hummingbird`,
    id: `ruby-throated_hummingbird`,
    scientific: `Archilochus colubris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Swallow-tailed Hummingbird`,
    id: `swallow-tailed_hummingbird`,
    scientific: `Eupetomena macroura`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Rufous-tailed Hummingbird`,
    id: `rufous-tailed_hummingbird`,
    scientific: `Amazilia tzacatl`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Great Blue Turaco`,
    id: `great_blue_turaco`,
    scientific: `Corythaeola cristata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey Go-away-bird`,
    id: `grey_go-away-bird`,
    scientific: `Crinifer concolor`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Eastern Plantain-eater`,
    id: `eastern_plantain-eater`,
    scientific: `Crinifer zonurus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Guira Cuckoo`,
    id: `guira_cuckoo`,
    scientific: `Guira guira`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Smooth-billed Ani`,
    id: `smooth-billed_ani`,
    scientific: `Crotophaga ani`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Brazil", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Groove-billed Ani`,
    id: `groove-billed_ani`,
    scientific: `Crotophaga sulcirostris`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Buff-headed Coucal`,
    id: `buff-headed_coucal`,
    scientific: `Centropus milo`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-browed Coucal`,
    id: `white-browed_coucal`,
    scientific: `Centropus superciliosus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Greater Coucal`,
    id: `greater_coucal`,
    scientific: `Centropus sinensis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Malagasy Coucal`,
    id: `malagasy_coucal`,
    scientific: `Centropus toulou`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Squirrel Cuckoo`,
    id: `squirrel_cuckoo`,
    scientific: `Piaya cayana`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Asian Koel`,
    id: `asian_koel`,
    scientific: `Eudynamys scolopaceus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India", "Malaysia"],
    commonRegionCodes: ["OR"],
    commonAll: true,
  },
  {
    name: `Pacific Koel`,
    id: `pacific_koel`,
    scientific: `Eudynamys orientalis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Channel-billed Cuckoo`,
    id: `channel-billed_cuckoo`,
    scientific: `Scythrops novaehollandiae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-chested Cuckoo`,
    id: `red-chested_cuckoo`,
    scientific: `Cuculus solitarius`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Common Cuckoo`,
    id: `common_cuckoo`,
    scientific: `Cuculus canorus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Spain", "Denmark", "United Kingdom"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Rock Dove`,
    id: `rock_dove`,
    scientific: `Columba livia`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["Worldwide"],
    commonCountryNames: [
      "Canada",
      "New Zealand",
      "Spain",
      "Austria",
      "Switzerland",
      "Denmark",
      "United Kingdom",
    ],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Speckled Pigeon`,
    id: `speckled_pigeon`,
    scientific: `Columba guinea`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [
      "South Africa",
      "Tanzania",
      "Kenya",
      "Uganda",
      "Zambia",
    ],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Common Wood Pigeon`,
    id: `common_wood_pigeon`,
    scientific: `Columba palumbus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Poland",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Yellow-legged Pigeon`,
    id: `yellow-legged_pigeon`,
    scientific: `Columba pallidiceps`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Picazuro Pigeon`,
    id: `picazuro_pigeon`,
    scientific: `Patagioenas picazuro`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Malagasy Turtle Dove`,
    id: `malagasy_turtle_dove`,
    scientific: `Nesoenas picturatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Turtle Dove`,
    id: `european_turtle_dove`,
    scientific: `Streptopelia turtur`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece", "Estonia", "United Kingdom"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Oriental Turtle Dove`,
    id: `oriental_turtle_dove`,
    scientific: `Streptopelia orientalis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Eurasian Collared Dove`,
    id: `eurasian_collared_dove`,
    scientific: `Streptopelia decaocto`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: ["PAL", "OR"],
    commonAll: false,
  },
  {
    name: `African Collared Dove`,
    id: `african_collared_dove`,
    scientific: `Streptopelia roseogrisea`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: true,
  },
  {
    name: `Mourning Collared Dove`,
    id: `mourning_collared_dove`,
    scientific: `Streptopelia decipiens`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Red-eyed Dove`,
    id: `red-eyed_dove`,
    scientific: `Streptopelia semitorquata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF", "PAL"],
    commonCountryNames: [
      "South Africa",
      "Tanzania",
      "Kenya",
      "Uganda",
      "Zambia",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Ring-necked Dove`,
    id: `ring-necked_dove`,
    scientific: `Streptopelia capicola`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Spotted Dove`,
    id: `spotted_dove`,
    scientific: `Spilopelia chinensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India", "Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR", "AU"],
    commonAll: false,
  },
  {
    name: `Laughing Dove`,
    id: `laughing_dove`,
    scientific: `Spilopelia senegalensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF", "PAL", "OR"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: true,
  },
  {
    name: `Brown Cuckoo-Dove`,
    id: `brown_cuckoo-dove`,
    scientific: `Macropygia phasianella`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Crested Pigeon`,
    id: `crested_pigeon`,
    scientific: `Ocyphaps lophotes`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Zebra Dove`,
    id: `zebra_dove`,
    scientific: `Geopelia striata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China", "Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Inca Dove`,
    id: `inca_dove`,
    scientific: `Columbina inca`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Ground Dove`,
    id: `common_ground_dove`,
    scientific: `Columbina passerina`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Ruddy Ground Dove`,
    id: `ruddy_ground_dove`,
    scientific: `Columbina talpacoti`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Picui Ground Dove`,
    id: `picui_ground_dove`,
    scientific: `Columbina picui`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-tipped Dove`,
    id: `white-tipped_dove`,
    scientific: `Leptotila verreauxi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Argentina", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Mourning Dove`,
    id: `mourning_dove`,
    scientific: `Zenaida macroura`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Eared Dove`,
    id: `eared_dove`,
    scientific: `Zenaida auriculata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-winged Dove`,
    id: `white-winged_dove`,
    scientific: `Zenaida asiatica`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: true,
  },
  {
    name: `Yellow-footed Green Pigeon`,
    id: `yellow-footed_green_pigeon`,
    scientific: `Treron phoenicopterus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Madagascar Green Pigeon`,
    id: `madagascar_green_pigeon`,
    scientific: `Treron australis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Green Pigeon`,
    id: `african_green_pigeon`,
    scientific: `Treron calvus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Orange-fronted Fruit Dove`,
    id: `orange-fronted_fruit_dove`,
    scientific: `Ptilinopus aurantiifrons`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Superb Fruit Dove`,
    id: `superb_fruit_dove`,
    scientific: `Ptilinopus superbus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Silver-capped Fruit Dove`,
    id: `silver-capped_fruit_dove`,
    scientific: `Ptilinopus richardsii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-bellied Fruit Dove`,
    id: `red-bellied_fruit_dove`,
    scientific: `Ptilinopus greyi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-bibbed Fruit Dove`,
    id: `yellow-bibbed_fruit_dove`,
    scientific: `Ptilinopus solomonensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Finsch's Imperial Pigeon`,
    id: `finsch's_imperial_pigeon`,
    scientific: `Ducula finschii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Island Imperial Pigeon`,
    id: `island_imperial_pigeon`,
    scientific: `Ducula pistrinaria`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Collared Imperial Pigeon`,
    id: `collared_imperial_pigeon`,
    scientific: `Ducula mullerii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Torresian Imperial Pigeon`,
    id: `torresian_imperial_pigeon`,
    scientific: `Ducula spilorrhoa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `New Zealand Pigeon`,
    id: `new_zealand_pigeon`,
    scientific: `Hemiphaga novaeseelandiae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Madagascar Forest Rail`,
    id: `madagascar_forest_rail`,
    scientific: `Mentocrex kioloides`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey-cowled Wood Rail`,
    id: `grey-cowled_wood_rail`,
    scientific: `Aramides cajaneus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Buff-banded Rail`,
    id: `buff-banded_rail`,
    scientific: `Hypotaenidia philippensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU", "PO"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Roviana Rail`,
    id: `roviana_rail`,
    scientific: `Hypotaenidia rovianae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Gallinule`,
    id: `common_gallinule`,
    scientific: `Gallinula galeata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "SA", "PO"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Moorhen`,
    id: `common_moorhen`,
    scientific: `Gallinula chloropus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF", "IO", "OR", "PO"],
    commonCountryNames: [
      "China",
      "Poland",
      "Netherlands",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Eurasian Coot`,
    id: `eurasian_coot`,
    scientific: `Fulica atra`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR", "AU"],
    commonCountryNames: [
      "Ireland",
      "Poland",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Czech Republic",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-breasted Waterhen`,
    id: `white-breasted_waterhen`,
    scientific: `Amaurornis phoenicurus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Indonesia", "Malaysia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey Crowned Crane`,
    id: `grey_crowned_crane`,
    scientific: `Balearica regulorum`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Sandhill Crane`,
    id: `sandhill_crane`,
    scientific: `Antigone canadensis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Common Crane`,
    id: `common_crane`,
    scientific: `Grus grus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Sweden", "Norway"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Little Grebe`,
    id: `little_grebe`,
    scientific: `Tachybaptus ruficollis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF", "OR"],
    commonCountryNames: ["Netherlands", "Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australasian Grebe`,
    id: `australasian_grebe`,
    scientific: `Tachybaptus novaehollandiae`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Madagascar Grebe`,
    id: `madagascar_grebe`,
    scientific: `Tachybaptus pelzelnii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Great Crested Grebe`,
    id: `great_crested_grebe`,
    scientific: `Podiceps cristatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF", "OR", "AU"],
    commonCountryNames: ["Poland", "Czech Republic"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Greater Flamingo`,
    id: `greater_flamingo`,
    scientific: `Phoenicopterus roseus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Snowy Sheathbill`,
    id: `snowy_sheathbill`,
    scientific: `Chionis albus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Eurasian Oystercatcher`,
    id: `eurasian_oystercatcher`,
    scientific: `Haematopus ostralegus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Finland", "Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `South Island Oystercatcher`,
    id: `south_island_oystercatcher`,
    scientific: `Haematopus finschi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-winged Stilt`,
    id: `black-winged_stilt`,
    scientific: `Himantopus himantopus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF", "OR"],
    commonCountryNames: ["Kenya"],
    commonRegionCodes: ["AF", "AU"],
    commonAll: false,
  },
  {
    name: `Pied Stilt`,
    id: `pied_stilt`,
    scientific: `Himantopus leucocephalus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-necked Stilt`,
    id: `black-necked_stilt`,
    scientific: `Himantopus mexicanus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA", "PO"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `American Avocet`,
    id: `american_avocet`,
    scientific: `Recurvirostra americana`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Common Ringed Plover`,
    id: `common_ringed_plover`,
    scientific: `Charadrius hiaticula`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Three-banded Plover`,
    id: `three-banded_plover`,
    scientific: `Charadrius tricollaris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Northern Lapwing`,
    id: `northern_lapwing`,
    scientific: `Vanellus vanellus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Ireland",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Finland",
      "Denmark",
      "Belgium",
      "Estonia",
      "Ukraine",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Blacksmith Lapwing`,
    id: `blacksmith_lapwing`,
    scientific: `Vanellus armatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Spur-winged Lapwing`,
    id: `spur-winged_lapwing`,
    scientific: `Vanellus spinosus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey-headed Lapwing`,
    id: `grey-headed_lapwing`,
    scientific: `Vanellus cinereus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-wattled Lapwing`,
    id: `red-wattled_lapwing`,
    scientific: `Vanellus indicus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Masked Lapwing`,
    id: `masked_lapwing`,
    scientific: `Vanellus miles`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Southern Lapwing`,
    id: `southern_lapwing`,
    scientific: `Vanellus chilensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Brazil", "Argentina", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["SA"],
    commonAll: true,
  },
  {
    name: `African Jacana`,
    id: `african_jacana`,
    scientific: `Actophilornis africanus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Eurasian Whimbrel`,
    id: `eurasian_whimbrel`,
    scientific: `Numenius phaeopus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-tailed Godwit`,
    id: `black-tailed_godwit`,
    scientific: `Limosa limosa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `American Woodcock`,
    id: `american_woodcock`,
    scientific: `Scolopax minor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Snipe`,
    id: `common_snipe`,
    scientific: `Gallinago gallinago`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Ireland", "Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Wilson's Snipe`,
    id: `wilson's_snipe`,
    scientific: `Gallinago delicata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Red-necked Phalarope`,
    id: `red-necked_phalarope`,
    scientific: `Phalaropus lobatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Redshank`,
    id: `common_redshank`,
    scientific: `Tringa totanus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Arctic Tern`,
    id: `arctic_tern`,
    scientific: `Sterna paradisaea`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "NA"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Antarctic Tern`,
    id: `antarctic_tern`,
    scientific: `Sterna vittata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Common Tern`,
    id: `common_tern`,
    scientific: `Sterna hirundo`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "NA"],
    commonCountryNames: ["Netherlands", "Denmark"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-legged Kittiwake`,
    id: `black-legged_kittiwake`,
    scientific: `Rissa tridactyla`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "NA"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Bonaparte's Gull`,
    id: `bonaparte's_gull`,
    scientific: `Chroicocephalus philadelphia`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Silver Gull`,
    id: `silver_gull`,
    scientific: `Chroicocephalus novaehollandiae`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: true,
  },
  {
    name: `Black-headed Gull`,
    id: `black-headed_gull`,
    scientific: `Chroicocephalus ridibundus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "China",
      "Japan",
      "Ireland",
      "Poland",
      "Russia",
      "Netherlands",
      "Italy",
      "Finland",
      "Denmark",
      "Czech Republic",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Iceland",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey-headed Gull`,
    id: `grey-headed_gull`,
    scientific: `Chroicocephalus cirrocephalus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA", "AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-tailed Gull`,
    id: `black-tailed_gull`,
    scientific: `Larus crassirostris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Gull`,
    id: `common_gull`,
    scientific: `Larus canus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: [
      "Ireland",
      "Finland",
      "Estonia",
      "Ukraine",
      "Iceland",
      "Iceland",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Ring-billed Gull`,
    id: `ring-billed_gull`,
    scientific: `Larus delawarensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Kelp Gull`,
    id: `kelp_gull`,
    scientific: `Larus dominicanus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA", "AU", "AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `European Herring Gull`,
    id: `european_herring_gull`,
    scientific: `Larus argentatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Canada",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Denmark",
      "Czech Republic",
      "Estonia",
      "Ukraine",
      "Iceland",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL", "NA"],
    commonAll: false,
  },
  {
    name: `Great Black-backed Gull`,
    id: `great_black-backed_gull`,
    scientific: `Larus marinus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `California Gull`,
    id: `california_gull`,
    scientific: `Larus californicus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `American Herring Gull`,
    id: `american_herring_gull`,
    scientific: `Larus smithsonianus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Great Skua`,
    id: `great_skua`,
    scientific: `Stercorarius skua`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `South Polar Skua`,
    id: `south_polar_skua`,
    scientific: `Stercorarius maccormicki`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Black Guillemot`,
    id: `black_guillemot`,
    scientific: `Cepphus grylle`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Murre`,
    id: `common_murre`,
    scientific: `Uria aalge`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-throated Loon`,
    id: `red-throated_loon`,
    scientific: `Gavia stellata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Loon`,
    id: `common_loon`,
    scientific: `Gavia immer`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Emperor Penguin`,
    id: `emperor_penguin`,
    scientific: `Aptenodytes forsteri`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Adelie Penguin`,
    id: `adelie_penguin`,
    scientific: `Pygoscelis adeliae`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Chinstrap Penguin`,
    id: `chinstrap_penguin`,
    scientific: `Pygoscelis antarcticus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Gentoo Penguin`,
    id: `gentoo_penguin`,
    scientific: `Pygoscelis papua`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Little Penguin`,
    id: `little_penguin`,
    scientific: `Eudyptula minor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Penguin`,
    id: `african_penguin`,
    scientific: `Spheniscus demersus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Wilson's Storm Petrel`,
    id: `wilson's_storm_petrel`,
    scientific: `Oceanites oceanicus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Black-bellied Storm Petrel`,
    id: `black-bellied_storm_petrel`,
    scientific: `Fregetta tropica`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Snowy Albatross`,
    id: `snowy_albatross`,
    scientific: `Diomedea exulans`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Black-browed Albatross`,
    id: `black-browed_albatross`,
    scientific: `Thalassarche melanophris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Southern Giant Petrel`,
    id: `southern_giant_petrel`,
    scientific: `Macronectes giganteus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Northern Giant Petrel`,
    id: `northern_giant_petrel`,
    scientific: `Macronectes halli`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Northern Fulmar`,
    id: `northern_fulmar`,
    scientific: `Fulmarus glacialis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Southern Fulmar`,
    id: `southern_fulmar`,
    scientific: `Fulmarus glacialoides`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Antarctic Petrel`,
    id: `antarctic_petrel`,
    scientific: `Thalassoica antarctica`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Cape Petrel`,
    id: `cape_petrel`,
    scientific: `Daption capense`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Snow Petrel`,
    id: `snow_petrel`,
    scientific: `Pagodroma nivea`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `Antarctic Prion`,
    id: `antarctic_prion`,
    scientific: `Pachyptila desolata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO", "AN"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `South Georgia Diving Petrel`,
    id: `south_georgia_diving_petrel`,
    scientific: `Pelecanoides georgicus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `African Openbill`,
    id: `african_openbill`,
    scientific: `Anastomus lamelligerus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Asian Openbill`,
    id: `asian_openbill`,
    scientific: `Anastomus oscitans`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Marabou Stork`,
    id: `marabou_stork`,
    scientific: `Leptoptilos crumenifer`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Yellow-billed Stork`,
    id: `yellow-billed_stork`,
    scientific: `Mycteria ibis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Painted Stork`,
    id: `painted_stork`,
    scientific: `Mycteria leucocephala`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Maguari Stork`,
    id: `maguari_stork`,
    scientific: `Ciconia maguari`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `White Stork`,
    id: `white_stork`,
    scientific: `Ciconia ciconia`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["Portugal", "Poland"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Australasian Gannet`,
    id: `australasian_gannet`,
    scientific: `Morus serrator`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Darter`,
    id: `african_darter`,
    scientific: `Anhinga rufa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF", "PAL"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Little Pied Cormorant`,
    id: `little_pied_cormorant`,
    scientific: `Microcarbo melanoleucos`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Australian Pied Cormorant`,
    id: `australian_pied_cormorant`,
    scientific: `Phalacrocorax varius`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Indian Cormorant`,
    id: `indian_cormorant`,
    scientific: `Phalacrocorax fuscicollis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Cormorant`,
    id: `japanese_cormorant`,
    scientific: `Phalacrocorax capillatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-breasted Cormorant`,
    id: `white-breasted_cormorant`,
    scientific: `Phalacrocorax lucidus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Great Cormorant`,
    id: `great_cormorant`,
    scientific: `Phalacrocorax carbo`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AU", "OR", "NA", "AF"],
    commonCountryNames: ["Denmark"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Shag`,
    id: `european_shag`,
    scientific: `Gulosus aristotelis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Antarctic Shag`,
    id: `antarctic_shag`,
    scientific: `Leucocarbo bransfieldensis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SO"],
    commonCountryNames: [],
    commonRegionCodes: ["AN"],
    commonAll: false,
  },
  {
    name: `African Sacred Ibis`,
    id: `african_sacred_ibis`,
    scientific: `Threskiornis aethiopicus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Black-headed Ibis`,
    id: `black-headed_ibis`,
    scientific: `Threskiornis melanocephalus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Australian White Ibis`,
    id: `australian_white_ibis`,
    scientific: `Threskiornis molucca`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia", "Australia"],
    commonRegionCodes: ["AU"],
    commonAll: true,
  },
  {
    name: `Straw-necked Ibis`,
    id: `straw-necked_ibis`,
    scientific: `Threskiornis spinicollis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Hadada Ibis`,
    id: `hadada_ibis`,
    scientific: `Bostrychia hagedash`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [
      "South Africa",
      "Tanzania",
      "Kenya",
      "Uganda",
      "Zambia",
    ],
    commonRegionCodes: ["AF"],
    commonAll: true,
  },
  {
    name: `Buff-necked Ibis`,
    id: `buff-necked_ibis`,
    scientific: `Theristicus caudatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Bare-faced Ibis`,
    id: `bare-faced_ibis`,
    scientific: `Phimosus infuscatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Scarlet Ibis`,
    id: `scarlet_ibis`,
    scientific: `Eudocimus ruber`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `African Spoonbill`,
    id: `african_spoonbill`,
    scientific: `Platalea alba`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `American Bittern`,
    id: `american_bittern`,
    scientific: `Botaurus lentiginosus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Black-crowned Night Heron`,
    id: `black-crowned_night_heron`,
    scientific: `Nycticorax nycticorax`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["Worldwide"],
    commonCountryNames: [
      "China",
      "Spain",
      "Argentina",
      "Indonesia",
      "Malaysia",
      "Thailand",
    ],
    commonRegionCodes: ["NA", "OR"],
    commonAll: false,
  },
  {
    name: `Japanese Night Heron`,
    id: `japanese_night_heron`,
    scientific: `Gorsachius goisagi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Snowy Egret`,
    id: `snowy_egret`,
    scientific: `Egretta thula`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Little Egret`,
    id: `little_egret`,
    scientific: `Egretta garzetta`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF", "OR", "AU"],
    commonCountryNames: [
      "China",
      "Spain",
      "Uganda",
      "Zambia",
      "Indonesia",
      "Malaysia",
      "Thailand",
    ],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `White-faced Heron`,
    id: `white-faced_heron`,
    scientific: `Egretta novaehollandiae`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Striated Heron`,
    id: `striated_heron`,
    scientific: `Butorides striata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA", "PAL", "AF", "AU"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Malagasy Pond Heron`,
    id: `malagasy_pond_heron`,
    scientific: `Ardeola idae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar", "Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Indian Pond Heron`,
    id: `indian_pond_heron`,
    scientific: `Ardeola grayii`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Chinese Pond Heron`,
    id: `chinese_pond_heron`,
    scientific: `Ardeola bacchus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Javan Pond Heron`,
    id: `javan_pond_heron`,
    scientific: `Ardeola speciosa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eastern Cattle Egret`,
    id: `eastern_cattle_egret`,
    scientific: `Bubulcus coromandus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Great Egret`,
    id: `great_egret`,
    scientific: `Ardea alba`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "SA", "AF", "PAL", "OR", "AU"],
    commonCountryNames: ["China", "New Zealand", "Argentina"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Grey Heron`,
    id: `grey_heron`,
    scientific: `Ardea cinerea`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF", "OR"],
    commonCountryNames: ["Japan", "Tanzania", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Great Blue Heron`,
    id: `great_blue_heron`,
    scientific: `Ardea herodias`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Purple Heron`,
    id: `purple_heron`,
    scientific: `Ardea purpurea`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL", "OR", "AF"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Black-headed Heron`,
    id: `black-headed_heron`,
    scientific: `Ardea melanocephala`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Australian Pelican`,
    id: `australian_pelican`,
    scientific: `Pelecanus conspicillatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia", "Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `American White Pelican`,
    id: `american_white_pelican`,
    scientific: `Pelecanus erythrorhynchos`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Andean Condor`,
    id: `andean_condor`,
    scientific: `Vultur gryphus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Black Vulture`,
    id: `black_vulture`,
    scientific: `Coragyps atratus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Turkey Vulture`,
    id: `turkey_vulture`,
    scientific: `Cathartes aura`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Secretarybird`,
    id: `secretarybird`,
    scientific: `Sagittarius serpentarius`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Black-winged Kite`,
    id: `black-winged_kite`,
    scientific: `Elanus caeruleus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF", "OR", "AU"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Madagascar Harrier-Hawk`,
    id: `madagascar_harrier-hawk`,
    scientific: `Polyboroides radiatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-backed Vulture`,
    id: `white-backed_vulture`,
    scientific: `Gyps africanus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Rüppell's Vulture`,
    id: `rüppell's_vulture`,
    scientific: `Gyps rueppelli`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Crested Serpent Eagle`,
    id: `crested_serpent_eagle`,
    scientific: `Spilornis cheela`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Harpy Eagle`,
    id: `harpy_eagle`,
    scientific: `Harpia harpyja`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Brown Goshawk`,
    id: `brown_goshawk`,
    scientific: `Accipiter fasciatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Sparrowhawk`,
    id: `japanese_sparrowhawk`,
    scientific: `Accipiter gularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Sparrowhawk`,
    id: `eurasian_sparrowhawk`,
    scientific: `Accipiter nisus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Greece",
      "Portugal",
      "Russia",
      "Austria",
      "Switzerland",
      "Turkey",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Swamp Harrier`,
    id: `swamp_harrier`,
    scientific: `Circus approximans`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Malagasy Harrier`,
    id: `malagasy_harrier`,
    scientific: `Circus macrosceles`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black Kite`,
    id: `black_kite`,
    scientific: `Milvus migrans`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF", "OR", "AU"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Yellow-billed Kite`,
    id: `yellow-billed_kite`,
    scientific: `Milvus aegyptius`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Brahminy Kite`,
    id: `brahminy_kite`,
    scientific: `Haliastur indus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Bald Eagle`,
    id: `bald_eagle`,
    scientific: `Haliaeetus leucocephalus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `White-bellied Sea Eagle`,
    id: `white-bellied_sea_eagle`,
    scientific: `Icthyophaga leucogaster`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Sanford's Sea Eagle`,
    id: `sanford's_sea_eagle`,
    scientific: `Icthyophaga sanfordi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands", "Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Fish Eagle`,
    id: `african_fish_eagle`,
    scientific: `Icthyophaga vocifer`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Madagascar Fish Eagle`,
    id: `madagascar_fish_eagle`,
    scientific: `Icthyophaga vociferoides`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-collared Hawk`,
    id: `black-collared_hawk`,
    scientific: `Busarellus nigricollis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Red-tailed Hawk`,
    id: `red-tailed_hawk`,
    scientific: `Buteo jamaicensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Common Buzzard`,
    id: `common_buzzard`,
    scientific: `Buteo buteo`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Germany", "Russia"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Madagascar Buzzard`,
    id: `madagascar_buzzard`,
    scientific: `Buteo brachypterus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Little Owl`,
    id: `little_owl`,
    scientific: `Athene noctua`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Ferruginous Pygmy Owl`,
    id: `ferruginous_pygmy_owl`,
    scientific: `Glaucidium brasilianum`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Snowy Owl`,
    id: `snowy_owl`,
    scientific: `Bubo scandiacus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Pel's Fishing Owl`,
    id: `pel's_fishing_owl`,
    scientific: `Scotopelia peli`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Tawny Owl`,
    id: `tawny_owl`,
    scientific: `Strix aluco`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Germany"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Speckled Mousebird`,
    id: `speckled_mousebird`,
    scientific: `Colius striatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Resplendent Quetzal`,
    id: `resplendent_quetzal`,
    scientific: `Pharomachrus mocinno`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Black-headed Trogon`,
    id: `black-headed_trogon`,
    scientific: `Trogon melanocephalus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Gartered Trogon`,
    id: `gartered_trogon`,
    scientific: `Trogon caligatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Eurasian Hoopoe`,
    id: `eurasian_hoopoe`,
    scientific: `Upupa epops`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["Spain", "Greece", "Portugal"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `African Hoopoe`,
    id: `african_hoopoe`,
    scientific: `Upupa africana`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Madagascar Hoopoe`,
    id: `madagascar_hoopoe`,
    scientific: `Upupa marginata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Southern Yellow-billed Hornbill`,
    id: `southern_yellow-billed_hornbill`,
    scientific: `Tockus leucomelas`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `African Grey Hornbill`,
    id: `african_grey_hornbill`,
    scientific: `Lophoceros nasutus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Oriental Pied Hornbill`,
    id: `oriental_pied_hornbill`,
    scientific: `Anthracoceros albirostris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Indian Grey Hornbill`,
    id: `indian_grey_hornbill`,
    scientific: `Ocyceros birostris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Blyth's Hornbill`,
    id: `blyth's_hornbill`,
    scientific: `Rhyticeros plicatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Indian Roller`,
    id: `indian_roller`,
    scientific: `Coracias benghalensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Lilac-breasted Roller`,
    id: `lilac-breasted_roller`,
    scientific: `Coracias caudatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `European Roller`,
    id: `european_roller`,
    scientific: `Coracias garrulus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Oriental Dollarbird`,
    id: `oriental_dollarbird`,
    scientific: `Eurystomus orientalis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR", "AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Azure Dollarbird`,
    id: `azure_dollarbird`,
    scientific: `Eurystomus azureus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Paradise Kingfisher`,
    id: `common_paradise_kingfisher`,
    scientific: `Tanysiptera galatea`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Laughing Kookaburra`,
    id: `laughing_kookaburra`,
    scientific: `Dacelo novaeguineae`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Stork-billed Kingfisher`,
    id: `stork-billed_kingfisher`,
    scientific: `Pelargopsis capensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `White-throated Kingfisher`,
    id: `white-throated_kingfisher`,
    scientific: `Halcyon smyrnensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: true,
  },
  {
    name: `Black-capped Kingfisher`,
    id: `black-capped_kingfisher`,
    scientific: `Halcyon pileata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey-headed Kingfisher`,
    id: `grey-headed_kingfisher`,
    scientific: `Halcyon leucocephala`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Ultramarine Kingfisher`,
    id: `ultramarine_kingfisher`,
    scientific: `Todiramphus leucopygius`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Vanuatu Kingfisher`,
    id: `vanuatu_kingfisher`,
    scientific: `Todiramphus farquhari`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Collared Kingfisher`,
    id: `collared_kingfisher`,
    scientific: `Todiramphus chloris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Sacred Kingfisher`,
    id: `sacred_kingfisher`,
    scientific: `Todiramphus sanctus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Yellow-billed Kingfisher`,
    id: `yellow-billed_kingfisher`,
    scientific: `Syma torotoro`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Pygmy Kingfisher`,
    id: `african_pygmy_kingfisher`,
    scientific: `Ispidina picta`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Malachite Kingfisher`,
    id: `malachite_kingfisher`,
    scientific: `Corythornis cristatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Malagasy Kingfisher`,
    id: `malagasy_kingfisher`,
    scientific: `Corythornis vintsioides`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Kingfisher`,
    id: `common_kingfisher`,
    scientific: `Alcedo atthis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR", "AU"],
    commonCountryNames: [
      "China",
      "India",
      "Spain",
      "Italy",
      "Ukraine",
      "Croatia",
      "Hungary",
      "Indonesia",
      "Malaysia",
      "Thailand",
    ],
    commonRegionCodes: ["PAL", "OR"],
    commonAll: false,
  },
  {
    name: `Ringed Kingfisher`,
    id: `ringed_kingfisher`,
    scientific: `Megaceryle torquata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Belted Kingfisher`,
    id: `belted_kingfisher`,
    scientific: `Megaceryle alcyon`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Lesson's Motmot`,
    id: `lesson's_motmot`,
    scientific: `Momotus lessonii`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Amazonian Motmot`,
    id: `amazonian_motmot`,
    scientific: `Momotus momota`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Turquoise-browed Motmot`,
    id: `turquoise-browed_motmot`,
    scientific: `Eumomota superciliosa`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Little Bee-eater`,
    id: `little_bee-eater`,
    scientific: `Merops pusillus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `White-fronted Bee-eater`,
    id: `white-fronted_bee-eater`,
    scientific: `Merops bullockoides`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `White-throated Bee-eater`,
    id: `white-throated_bee-eater`,
    scientific: `Merops albicollis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Olive Bee-eater`,
    id: `olive_bee-eater`,
    scientific: `Merops superciliosus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rainbow Bee-eater`,
    id: `rainbow_bee-eater`,
    scientific: `Merops ornatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Chestnut-headed Bee-eater`,
    id: `chestnut-headed_bee-eater`,
    scientific: `Merops leschenaulti`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `European Bee-eater`,
    id: `european_bee-eater`,
    scientific: `Merops apiaster`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["Spain", "Greece"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Rufous-tailed Jacamar`,
    id: `rufous-tailed_jacamar`,
    scientific: `Galbula ruficauda`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `White-eared Puffbird`,
    id: `white-eared_puffbird`,
    scientific: `Nystalus chacuru`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Chestnut-eared Aracari`,
    id: `chestnut-eared_aracari`,
    scientific: `Pteroglossus castanotis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Collared Aracari`,
    id: `collared_aracari`,
    scientific: `Pteroglossus torquatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Keel-billed Toucan`,
    id: `keel-billed_toucan`,
    scientific: `Ramphastos sulfuratus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Toco Toucan`,
    id: `toco_toucan`,
    scientific: `Ramphastos toco`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `White-throated Toucan`,
    id: `white-throated_toucan`,
    scientific: `Ramphastos tucanus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Red-crowned Barbet`,
    id: `red-crowned_barbet`,
    scientific: `Psilopogon rafflesii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-rumped Tinkerbird`,
    id: `yellow-rumped_tinkerbird`,
    scientific: `Pogoniulus bilineatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-headed Barbet`,
    id: `white-headed_barbet`,
    scientific: `Lybius leucocephalus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-cheeked Woodpecker`,
    id: `black-cheeked_woodpecker`,
    scientific: `Melanerpes pucherani`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Red-crowned Woodpecker`,
    id: `red-crowned_woodpecker`,
    scientific: `Melanerpes rubricapillus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Golden-fronted Woodpecker`,
    id: `golden-fronted_woodpecker`,
    scientific: `Melanerpes aurifrons`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Sunda Pygmy Woodpecker`,
    id: `sunda_pygmy_woodpecker`,
    scientific: `Yungipicus moluccensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Pygmy Woodpecker`,
    id: `japanese_pygmy_woodpecker`,
    scientific: `Yungipicus kizuki`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Downy Woodpecker`,
    id: `downy_woodpecker`,
    scientific: `Dryobates pubescens`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Northern Flicker`,
    id: `northern_flicker`,
    scientific: `Colaptes auratus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `European Green Woodpecker`,
    id: `european_green_woodpecker`,
    scientific: `Picus viridis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Germany", "Greece", "France", "Italy"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Black-rumped Flameback`,
    id: `black-rumped_flameback`,
    scientific: `Dinopium benghalense`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-legged Seriema`,
    id: `red-legged_seriema`,
    scientific: `Cariama cristata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Crested Caracara`,
    id: `crested_caracara`,
    scientific: `Caracara plancus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Yellow-headed Caracara`,
    id: `yellow-headed_caracara`,
    scientific: `Milvago chimachima`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Common Kestrel`,
    id: `common_kestrel`,
    scientific: `Falco tinnunculus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: [
      "Germany",
      "Portugal",
      "France",
      "Austria",
      "Switzerland",
      "Czech Republic",
      "Belgium",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Malagasy Kestrel`,
    id: `malagasy_kestrel`,
    scientific: `Falco newtoni`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Nankeen Kestrel`,
    id: `nankeen_kestrel`,
    scientific: `Falco cenchroides`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `American Kestrel`,
    id: `american_kestrel`,
    scientific: `Falco sparverius`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["United States", "Canada", "Peru"],
    commonRegionCodes: ["NA", "SA"],
    commonAll: false,
  },
  {
    name: `Australian Hobby`,
    id: `australian_hobby`,
    scientific: `Falco longipennis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Yellow-tailed Black Cockatoo`,
    id: `yellow-tailed_black_cockatoo`,
    scientific: `Zanda funerea`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Palm Cockatoo`,
    id: `palm_cockatoo`,
    scientific: `Probosciger aterrimus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Galah`,
    id: `galah`,
    scientific: `Eolophus roseicapilla`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Solomons Cockatoo`,
    id: `solomons_cockatoo`,
    scientific: `Cacatua ducorpsii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Little Corella`,
    id: `little_corella`,
    scientific: `Cacatua sanguinea`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Sulphur-crested Cockatoo`,
    id: `sulphur-crested_cockatoo`,
    scientific: `Cacatua galerita`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Grey Parrot`,
    id: `grey_parrot`,
    scientific: `Psittacus erithacus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Monk Parakeet`,
    id: `monk_parakeet`,
    scientific: `Myiopsitta monachus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Spain", "Portugal", "Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-chevroned Parakeet`,
    id: `yellow-chevroned_parakeet`,
    scientific: `Brotogeris chiriri`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Orange-chinned Parakeet`,
    id: `orange-chinned_parakeet`,
    scientific: `Brotogeris jugularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Blue-headed Parrot`,
    id: `blue-headed_parrot`,
    scientific: `Pionus menstruus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Orange-winged Amazon`,
    id: `orange-winged_amazon`,
    scientific: `Amazona amazonica`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Green-rumped Parrotlet`,
    id: `green-rumped_parrotlet`,
    scientific: `Forpus passerinus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Hyacinth Macaw`,
    id: `hyacinth_macaw`,
    scientific: `Anodorhynchus hyacinthinus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Olive-throated Parakeet`,
    id: `olive-throated_parakeet`,
    scientific: `Eupsittula nana`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Blue-and-yellow Macaw`,
    id: `blue-and-yellow_macaw`,
    scientific: `Ara ararauna`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: ["SA"],
    commonAll: true,
  },
  {
    name: `Scarlet Macaw`,
    id: `scarlet_macaw`,
    scientific: `Ara macao`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Blue-crowned Parakeet`,
    id: `blue-crowned_parakeet`,
    scientific: `Thectocercus acuticaudatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `White-eyed Parakeet`,
    id: `white-eyed_parakeet`,
    scientific: `Psittacara leucophthalmus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Lesser Vasa Parrot`,
    id: `lesser_vasa_parrot`,
    scientific: `Coracopsis nigra`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australian King Parrot`,
    id: `australian_king_parrot`,
    scientific: `Alisterus scapularis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Eclectus Parrot`,
    id: `eclectus_parrot`,
    scientific: `Eclectus roratus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-cheeked Parrot`,
    id: `red-cheeked_parrot`,
    scientific: `Geoffroyus geoffroyi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rose-ringed Parakeet`,
    id: `rose-ringed_parakeet`,
    scientific: `Psittacula krameri`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF", "OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Crimson Rosella`,
    id: `crimson_rosella`,
    scientific: `Platycercus elegans`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Eastern Rosella`,
    id: `eastern_rosella`,
    scientific: `Platycercus eximius`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia", "New Zealand"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Duchess Lorikeet`,
    id: `duchess_lorikeet`,
    scientific: `Charmosynoides margarethae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-bibbed Lory`,
    id: `yellow-bibbed_lory`,
    scientific: `Lorius chlorocercus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rainbow Lorikeet`,
    id: `rainbow_lorikeet`,
    scientific: `Trichoglossus moluccanus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia", "Papua New Guinea"],
    commonRegionCodes: ["AU"],
    commonAll: true,
  },
  {
    name: `Indian Pitta`,
    id: `indian_pitta`,
    scientific: `Pitta brachyura`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Western Hooded Pitta`,
    id: `western_hooded_pitta`,
    scientific: `Pitta sordida`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-faced Pitta`,
    id: `black-faced_pitta`,
    scientific: `Pitta anerythra`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Plain Xenops`,
    id: `plain_xenops`,
    scientific: `Xenops genibarbis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Rufous Hornero`,
    id: `rufous_hornero`,
    scientific: `Furnarius rufus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: true,
  },
  {
    name: `Ochre-throated Foliage-gleaner`,
    id: `ochre-throated_foliage-gleaner`,
    scientific: `Automolus ochrolaemus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Rufous-fronted Thornbird`,
    id: `rufous-fronted_thornbird`,
    scientific: `Phacellodomus rufifrons`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rufous-capped Spinetail`,
    id: `rufous-capped_spinetail`,
    scientific: `Synallaxis ruficapilla`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rufous-breasted Spinetail`,
    id: `rufous-breasted_spinetail`,
    scientific: `Synallaxis erythrothorax`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Yellow-bellied Elaenia`,
    id: `yellow-bellied_elaenia`,
    scientific: `Elaenia flavogaster`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Black Phoebe`,
    id: `black_phoebe`,
    scientific: `Sayornis nigricans`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Yellow-browed Tyrant`,
    id: `yellow-browed_tyrant`,
    scientific: `Satrapa icterophrys`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Cattle Tyrant`,
    id: `cattle_tyrant`,
    scientific: `Machetornis rixosa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rusty-margined Flycatcher`,
    id: `rusty-margined_flycatcher`,
    scientific: `Myiozetetes cayanensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Social Flycatcher`,
    id: `social_flycatcher`,
    scientific: `Myiozetetes similis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: true,
  },
  {
    name: `Great Kiskadee`,
    id: `great_kiskadee`,
    scientific: `Pitangus sulphuratus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: [
      "Brazil",
      "Mexico",
      "Argentina",
      "Colombia",
      "Ecuador",
      "Peru",
    ],
    commonRegionCodes: ["MA", "SA"],
    commonAll: false,
  },
  {
    name: `Sulphur-bellied Flycatcher`,
    id: `sulphur-bellied_flycatcher`,
    scientific: `Myiodynastes luteiventris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Streaked Flycatcher`,
    id: `streaked_flycatcher`,
    scientific: `Myiodynastes maculatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Tropical Kingbird`,
    id: `tropical_kingbird`,
    scientific: `Tyrannus melancholicus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Fork-tailed Flycatcher`,
    id: `fork-tailed_flycatcher`,
    scientific: `Tyrannus savana`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Rufous Mourner`,
    id: `rufous_mourner`,
    scientific: `Rhytipterna holerythra`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Screaming Piha`,
    id: `screaming_piha`,
    scientific: `Lipaugus vociferans`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Long-tailed Manakin`,
    id: `long-tailed_manakin`,
    scientific: `Chiroxiphia linearis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Masked Tityra`,
    id: `masked_tityra`,
    scientific: `Tityra semifasciata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `White-winged Becard`,
    id: `white-winged_becard`,
    scientific: `Pachyramphus polychopterus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Superb Fairywren`,
    id: `superb_fairywren`,
    scientific: `Malurus cyaneus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Red-backed Fairywren`,
    id: `red-backed_fairywren`,
    scientific: `Malurus melanocephalus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Eastern Spinebill`,
    id: `eastern_spinebill`,
    scientific: `Acanthorhynchus tenuirostris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Tui`,
    id: `tui`,
    scientific: `Prosthemadera novaeseelandiae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `New Holland Honeyeater`,
    id: `new_holland_honeyeater`,
    scientific: `Phylidonyris novaehollandiae`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Guadalcanal Honeyeater`,
    id: `guadalcanal_honeyeater`,
    scientific: `Guadalcanaria inexpectata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Varied Honeyeater`,
    id: `varied_honeyeater`,
    scientific: `Gavicalis versicolor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-faced Honeyeater`,
    id: `yellow-faced_honeyeater`,
    scientific: `Caligavis chrysops`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Noisy Miner`,
    id: `noisy_miner`,
    scientific: `Manorina melanocephala`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Fairy Gerygone`,
    id: `fairy_gerygone`,
    scientific: `Gerygone palpebrosa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey Gerygone`,
    id: `grey_gerygone`,
    scientific: `Gerygone igata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-headed Gonolek`,
    id: `black-headed_gonolek`,
    scientific: `Laniarius erythrogaster`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Woodshrike`,
    id: `common_woodshrike`,
    scientific: `Tephrodornis pondicerianus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black Butcherbird`,
    id: `black_butcherbird`,
    scientific: `Melloria quoyi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australian Magpie`,
    id: `australian_magpie`,
    scientific: `Gymnorhina tibicen`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: true,
  },
  {
    name: `White-bellied Cuckooshrike`,
    id: `white-bellied_cuckooshrike`,
    scientific: `Coracina papuensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Cicadabird`,
    id: `common_cicadabird`,
    scientific: `Edolisoma tenuirostre`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Varied Sittella`,
    id: `varied_sittella`,
    scientific: `Daphoenositta chrysoptera`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Australian Golden Whistler`,
    id: `australian_golden_whistler`,
    scientific: `Pachycephala pectoralis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-eyed Vireo`,
    id: `white-eyed_vireo`,
    scientific: `Vireo griseus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australasian Figbird`,
    id: `australasian_figbird`,
    scientific: `Sphecotheres vieilloti`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Black-headed Oriole`,
    id: `black-headed_oriole`,
    scientific: `Oriolus larvatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Black-naped Oriole`,
    id: `black-naped_oriole`,
    scientific: `Oriolus chinensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Greater Racket-tailed Drongo`,
    id: `greater_racket-tailed_drongo`,
    scientific: `Dicrurus paradiseus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Fork-tailed Drongo`,
    id: `fork-tailed_drongo`,
    scientific: `Dicrurus adsimilis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black Drongo`,
    id: `black_drongo`,
    scientific: `Dicrurus macrocercus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["China", "India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `White-throated Fantail`,
    id: `white-throated_fantail`,
    scientific: `Rhipidura albicollis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Malaysian Pied Fantail`,
    id: `malaysian_pied_fantail`,
    scientific: `Rhipidura javanica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Willie Wagtail`,
    id: `willie_wagtail`,
    scientific: `Rhipidura leucophrys`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Grey Fantail`,
    id: `grey_fantail`,
    scientific: `Rhipidura albiscapa`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `New Zealand Fantail`,
    id: `new_zealand_fantail`,
    scientific: `Rhipidura fuliginosa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Malaita Fantail`,
    id: `malaita_fantail`,
    scientific: `Rhipidura malaitae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Paradise Flycatcher`,
    id: `african_paradise_flycatcher`,
    scientific: `Terpsiphone viridis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Indian Paradise Flycatcher`,
    id: `indian_paradise_flycatcher`,
    scientific: `Terpsiphone paradisi`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Malagasy Paradise Flycatcher`,
    id: `malagasy_paradise_flycatcher`,
    scientific: `Terpsiphone mutata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Kolombangara Monarch`,
    id: `kolombangara_monarch`,
    scientific: `Symposiachrus browni`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-collared Monarch`,
    id: `white-collared_monarch`,
    scientific: `Symposiachrus vidua`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Magpie-lark`,
    id: `magpie-lark`,
    scientific: `Grallina cyanoleuca`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Melanesian Flycatcher`,
    id: `melanesian_flycatcher`,
    scientific: `Myiagra caledonica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Iberian Grey Shrike`,
    id: `iberian_grey_shrike`,
    scientific: `Lanius meridionalis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Southern Fiscal`,
    id: `southern_fiscal`,
    scientific: `Lanius collaris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-backed Shrike`,
    id: `red-backed_shrike`,
    scientific: `Lanius collurio`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Turkey", "Hungary"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Brown Shrike`,
    id: `brown_shrike`,
    scientific: `Lanius cristatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Black-throated Jay`,
    id: `black-throated_jay`,
    scientific: `Cyanolyca pumilo`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Green Jay`,
    id: `green_jay`,
    scientific: `Cyanocorax luxuosus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Brown Jay`,
    id: `brown_jay`,
    scientific: `Psilorhinus morio`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-throated Magpie-Jay`,
    id: `white-throated_magpie-jay`,
    scientific: `Calocitta formosa`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Blue Jay`,
    id: `blue_jay`,
    scientific: `Cyanocitta cristata`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Eurasian Jay`,
    id: `eurasian_jay`,
    scientific: `Garrulus glandarius`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: [
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Azure-winged Magpie`,
    id: `azure-winged_magpie`,
    scientific: `Cyanopica cyanus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["China", "Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Iberian Magpie`,
    id: `iberian_magpie`,
    scientific: `Cyanopica cooki`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Spain"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-billed Blue Magpie`,
    id: `red-billed_blue_magpie`,
    scientific: `Urocissa erythroryncha`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Magpie`,
    id: `eurasian_magpie`,
    scientific: `Pica pica`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Western Jackdaw`,
    id: `western_jackdaw`,
    scientific: `Coloeus monedula`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Sweden", "Norway", "United Kingdom"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `House Crow`,
    id: `house_crow`,
    scientific: `Corvus splendens`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India", "Indonesia"],
    commonRegionCodes: ["OR"],
    commonAll: true,
  },
  {
    name: `White-billed Crow`,
    id: `white-billed_crow`,
    scientific: `Corvus woodfordi`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `American Crow`,
    id: `american_crow`,
    scientific: `Corvus brachyrhynchos`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: true,
  },
  {
    name: `Carrion Crow`,
    id: `carrion_crow`,
    scientific: `Corvus corone`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Japan",
      "Germany",
      "Ireland",
      "Poland",
      "Russia",
      "France",
      "Netherlands",
      "Italy",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Croatia",
      "United Kingdom",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Hooded Crow`,
    id: `hooded_crow`,
    scientific: `Corvus cornix`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Ireland",
      "Russia",
      "Italy",
      "Austria",
      "Switzerland",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Large-billed Crow`,
    id: `large-billed_crow`,
    scientific: `Corvus macrorhynchos`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["China", "Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Torresian Crow`,
    id: `torresian_crow`,
    scientific: `Corvus orru`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Australian Raven`,
    id: `australian_raven`,
    scientific: `Corvus coronoides`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Pied Crow`,
    id: `pied_crow`,
    scientific: `Corvus albus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [
      "South Africa",
      "Tanzania",
      "Kenya",
      "Uganda",
      "Zambia",
    ],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Northern Raven`,
    id: `northern_raven`,
    scientific: `Corvus corax`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA", "PAL"],
    commonCountryNames: ["Germany", "Iceland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-winged Chough`,
    id: `white-winged_chough`,
    scientific: `Corcorax melanorhamphos`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Pacific Robin`,
    id: `pacific_robin`,
    scientific: `Petroica pusilla`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU", "PO"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eastern Yellow Robin`,
    id: `eastern_yellow_robin`,
    scientific: `Eopsaltria australis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Cedar Waxwing`,
    id: `cedar_waxwing`,
    scientific: `Bombycilla cedrorum`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Grey-headed Canary-flycatcher`,
    id: `grey-headed_canary-flycatcher`,
    scientific: `Culicicapa ceylonensis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Crested Tit`,
    id: `crested_tit`,
    scientific: `Lophophanes cristatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Tufted Titmouse`,
    id: `tufted_titmouse`,
    scientific: `Baeolophus bicolor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Varied Tit`,
    id: `varied_tit`,
    scientific: `Sittiparus varius`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Carolina Chickadee`,
    id: `carolina_chickadee`,
    scientific: `Poecile carolinensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-capped Chickadee`,
    id: `black-capped_chickadee`,
    scientific: `Poecile atricapillus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Eurasian Blue Tit`,
    id: `eurasian_blue_tit`,
    scientific: `Cyanistes caeruleus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: true,
  },
  {
    name: `Great Tit`,
    id: `great_tit`,
    scientific: `Parus major`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "China",
      "Japan",
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Japanese Tit`,
    id: `japanese_tit`,
    scientific: `Parus minor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Madagascar Lark`,
    id: `madagascar_lark`,
    scientific: `Eremopterix hova`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Skylark`,
    id: `eurasian_skylark`,
    scientific: `Alauda arvensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Japan",
      "Ireland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Italy",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Crested Lark`,
    id: `crested_lark`,
    scientific: `Galerida cristata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["Spain"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Brown-eared Bulbul`,
    id: `brown-eared_bulbul`,
    scientific: `Hypsipetes amaurotis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Malagasy Bulbul`,
    id: `malagasy_bulbul`,
    scientific: `Hypsipetes madagascariensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Olive-winged Bulbul`,
    id: `olive-winged_bulbul`,
    scientific: `Pycnonotus plumosus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Light-vented Bulbul`,
    id: `light-vented_bulbul`,
    scientific: `Pycnonotus sinensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China", "China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-whiskered Bulbul`,
    id: `red-whiskered_bulbul`,
    scientific: `Pycnonotus jocosus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: true,
  },
  {
    name: `Yellow-vented Bulbul`,
    id: `yellow-vented_bulbul`,
    scientific: `Pycnonotus goiavier`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-vented Bulbul`,
    id: `red-vented_bulbul`,
    scientific: `Pycnonotus cafer`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Common Bulbul`,
    id: `common_bulbul`,
    scientific: `Pycnonotus barbatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: true,
  },
  {
    name: `White-rumped Swallow`,
    id: `white-rumped_swallow`,
    scientific: `Tachycineta leucorrhoa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Brown-chested Martin`,
    id: `brown-chested_martin`,
    scientific: `Progne tapera`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Grey-breasted Martin`,
    id: `grey-breasted_martin`,
    scientific: `Progne chalybea`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Blue-and-white Swallow`,
    id: `blue-and-white_swallow`,
    scientific: `Pygochelidon cyanoleuca`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Crag Martin`,
    id: `eurasian_crag_martin`,
    scientific: `Ptyonoprogne rupestris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Pacific Swallow`,
    id: `pacific_swallow`,
    scientific: `Hirundo tahitica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU", "PO"],
    commonCountryNames: [
      "Japan",
      "Indonesia",
      "Malaysia",
      "Thailand",
      "Papua New Guinea",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Welcome Swallow`,
    id: `welcome_swallow`,
    scientific: `Hirundo neoxena`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: ["Australia", "New Zealand"],
    commonRegionCodes: ["AU"],
    commonAll: true,
  },
  {
    name: `Barn Swallow`,
    id: `barn_swallow`,
    scientific: `Hirundo rustica`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["Worldwide"],
    commonCountryNames: [
      "China",
      "Japan",
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "Indonesia",
      "Malaysia",
      "Thailand",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Common House Martin`,
    id: `common_house_martin`,
    scientific: `Delichon urbicum`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Russia",
      "Netherlands",
      "Denmark",
      "Turkey",
      "United Kingdom",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-rumped Swallow`,
    id: `red-rumped_swallow`,
    scientific: `Cecropis daurica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR", "AF"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Bush Warbler`,
    id: `japanese_bush_warbler`,
    scientific: `Horornis diphone`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Cetti's Warbler`,
    id: `cetti's_warbler`,
    scientific: `Cettia cetti`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Spain", "Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Western Bonelli's Warbler`,
    id: `western_bonelli's_warbler`,
    scientific: `Phylloscopus bonelli`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-browed Warbler`,
    id: `yellow-browed_warbler`,
    scientific: `Phylloscopus inornatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Willow Warbler`,
    id: `willow_warbler`,
    scientific: `Phylloscopus trochilus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Finland",
      "Estonia",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Malagasy Brush Warbler`,
    id: `malagasy_brush_warbler`,
    scientific: `Nesillas typica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Australian Reed Warbler`,
    id: `australian_reed_warbler`,
    scientific: `Acrocephalus australis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AU"],
    commonCountryNames: [],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Common Reed Warbler`,
    id: `common_reed_warbler`,
    scientific: `Acrocephalus scirpaceus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Black-capped Donacobius`,
    id: `black-capped_donacobius`,
    scientific: `Donacobius atricapilla`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Zitting Cisticola`,
    id: `zitting_cisticola`,
    scientific: `Cisticola juncidis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "AF", "OR", "AU"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Madagascar Cisticola`,
    id: `madagascar_cisticola`,
    scientific: `Cisticola cherina`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-bellied Prinia`,
    id: `yellow-bellied_prinia`,
    scientific: `Prinia flaviventris`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Plain Prinia`,
    id: `plain_prinia`,
    scientific: `Prinia inornata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Tailorbird`,
    id: `common_tailorbird`,
    scientific: `Orthotomus sutorius`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India", "Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Eurasian Blackcap`,
    id: `eurasian_blackcap`,
    scientific: `Sylvia atricapilla`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Sardinian Warbler`,
    id: `sardinian_warbler`,
    scientific: `Curruca melanocephala`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Spain", "Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Whitethroat`,
    id: `common_whitethroat`,
    scientific: `Curruca communis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Russia", "France", "Belgium"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Vinous-throated Parrotbill`,
    id: `vinous-throated_parrotbill`,
    scientific: `Suthora webbiana`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Warbling White-eye`,
    id: `warbling_white-eye`,
    scientific: `Zosterops japonicus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR", "AU"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Indian White-eye`,
    id: `indian_white-eye`,
    scientific: `Zosterops palpebrosus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Cape White-eye`,
    id: `cape_white-eye`,
    scientific: `Zosterops virens`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Malagasy White-eye`,
    id: `malagasy_white-eye`,
    scientific: `Zosterops maderaspatanus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Silvereye`,
    id: `silvereye`,
    scientific: `Zosterops lateralis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AU", "PO"],
    commonCountryNames: ["New Zealand"],
    commonRegionCodes: ["AU"],
    commonAll: false,
  },
  {
    name: `Solomons White-eye`,
    id: `solomons_white-eye`,
    scientific: `Zosterops kulambangrae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Jungle Babbler`,
    id: `jungle_babbler`,
    scientific: `Argya striata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Chinese Hwamei`,
    id: `chinese_hwamei`,
    scientific: `Garrulax canorus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Asian Fairy-bluebird`,
    id: `asian_fairy-bluebird`,
    scientific: `Irena puella`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Ruby-crowned Kinglet`,
    id: `ruby-crowned_kinglet`,
    scientific: `Corthylio calendula`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Goldcrest`,
    id: `goldcrest`,
    scientific: `Regulus regulus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Veracruz Wren`,
    id: `veracruz_wren`,
    scientific: `Campylorhynchus rufinucha`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Rufous-and-white Wren`,
    id: `rufous-and-white_wren`,
    scientific: `Thryophilus rufalbus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: true,
  },
  {
    name: `Carolina Wren`,
    id: `carolina_wren`,
    scientific: `Thryothorus ludovicianus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Wren`,
    id: `eurasian_wren`,
    scientific: `Troglodytes troglodytes`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `House Wren`,
    id: `house_wren`,
    scientific: `Troglodytes aedon`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Brazil", "Argentina", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Blue-gray Gnatcatcher`,
    id: `blue-gray_gnatcatcher`,
    scientific: `Polioptila caerulea`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-breasted Nuthatch`,
    id: `white-breasted_nuthatch`,
    scientific: `Sitta carolinensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Western Rock Nuthatch`,
    id: `western_rock_nuthatch`,
    scientific: `Sitta neumayer`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Nuthatch`,
    id: `eurasian_nuthatch`,
    scientific: `Sitta europaea`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Spain",
      "Greece",
      "Italy",
      "Austria",
      "Czech Republic",
      "Estonia",
      "Turkey",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Northern Mockingbird`,
    id: `northern_mockingbird`,
    scientific: `Mimus polyglottos`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada", "Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Tropical Mockingbird`,
    id: `tropical_mockingbird`,
    scientific: `Mimus gilvus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Chalk-browed Mockingbird`,
    id: `chalk-browed_mockingbird`,
    scientific: `Mimus saturninus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Asian Glossy Starling`,
    id: `asian_glossy_starling`,
    scientific: `Aplonis panayensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-faced Myna`,
    id: `yellow-faced_myna`,
    scientific: `Mino dumontii`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Long-tailed Myna`,
    id: `long-tailed_myna`,
    scientific: `Mino kreffti`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Crested Myna`,
    id: `crested_myna`,
    scientific: `Acridotheres cristatellus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Jungle Myna`,
    id: `jungle_myna`,
    scientific: `Acridotheres fuscus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Common Myna`,
    id: `common_myna`,
    scientific: `Acridotheres tristis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [
      "Australia",
      "India",
      "Indonesia",
      "Malaysia",
      "Thailand",
    ],
    commonRegionCodes: ["OR"],
    commonAll: true,
  },
  {
    name: `White-cheeked Starling`,
    id: `white-cheeked_starling`,
    scientific: `Spodiopsar cineraceus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-collared Starling`,
    id: `black-collared_starling`,
    scientific: `Gracupica nigricollis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Brahminy Starling`,
    id: `brahminy_starling`,
    scientific: `Sturnia pagodarum`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Starling`,
    id: `common_starling`,
    scientific: `Sturnus vulgaris`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "United States",
      "Canada",
      "Germany",
      "New Zealand",
      "Ireland",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: true,
  },
  {
    name: `Spotless Starling`,
    id: `spotless_starling`,
    scientific: `Sturnus unicolor`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Spain", "Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Cape Starling`,
    id: `cape_starling`,
    scientific: `Lamprotornis nitens`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Greater Blue-eared Starling`,
    id: `greater_blue-eared_starling`,
    scientific: `Lamprotornis chalybaeus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Superb Starling`,
    id: `superb_starling`,
    scientific: `Lamprotornis superbus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Madagascar Starling`,
    id: `madagascar_starling`,
    scientific: `Hartlaubius auratus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Violet-backed Starling`,
    id: `violet-backed_starling`,
    scientific: `Cinnyricinclus leucogaster`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-winged Starling`,
    id: `red-winged_starling`,
    scientific: `Onychognathus morio`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-billed Oxpecker`,
    id: `yellow-billed_oxpecker`,
    scientific: `Buphagus africanus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Eastern Bluebird`,
    id: `eastern_bluebird`,
    scientific: `Sialia sialis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Song Thrush`,
    id: `song_thrush`,
    scientific: `Turdus philomelos`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "New Zealand",
      "Ireland",
      "Poland",
      "France",
      "Austria",
      "Switzerland",
      "Czech Republic",
      "Belgium",
      "Croatia",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Mistle Thrush`,
    id: `mistle_thrush`,
    scientific: `Turdus viscivorus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Ireland",
      "France",
      "Sweden",
      "Norway",
      "Austria",
      "Switzerland",
      "Belgium",
      "Estonia",
      "Ukraine",
    ],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Olive Thrush`,
    id: `olive_thrush`,
    scientific: `Turdus olivaceus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Redwing`,
    id: `redwing`,
    scientific: `Turdus iliacus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Ireland", "Russia", "Sweden", "Norway", "Finland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Blackbird`,
    id: `common_blackbird`,
    scientific: `Turdus merula`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: [
      "Germany",
      "Germany",
      "New Zealand",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Poland",
      "Russia",
      "France",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Netherlands",
      "Italy",
      "Italy",
      "Finland",
      "Austria",
      "Austria",
      "Switzerland",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Czech Republic",
      "Belgium",
      "Belgium",
      "Belgium",
      "Estonia",
      "Estonia",
      "Ukraine",
      "Ukraine",
      "Turkey",
      "Turkey",
      "Croatia",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: true,
  },
  {
    name: `Island Thrush`,
    id: `island_thrush`,
    scientific: `Turdus poliocephalus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU", "PO"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Fieldfare`,
    id: `fieldfare`,
    scientific: `Turdus pilaris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Sweden", "Norway"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `American Robin`,
    id: `american_robin`,
    scientific: `Turdus migratorius`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada", "Mexico"],
    commonRegionCodes: ["NA"],
    commonAll: true,
  },
  {
    name: `Rufous-bellied Thrush`,
    id: `rufous-bellied_thrush`,
    scientific: `Turdus rufiventris`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Clay-colored Thrush`,
    id: `clay-colored_thrush`,
    scientific: `Turdus grayi`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: true,
  },
  {
    name: `Oriental Magpie-Robin`,
    id: `oriental_magpie-robin`,
    scientific: `Copsychus saularis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: ["China", "India", "Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Madagascar Magpie-Robin`,
    id: `madagascar_magpie-robin`,
    scientific: `Copsychus albospecularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Indian Robin`,
    id: `indian_robin`,
    scientific: `Copsychus fulicatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-rumped Shama`,
    id: `white-rumped_shama`,
    scientific: `Copsychus malabaricus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Fiscal Flycatcher`,
    id: `fiscal_flycatcher`,
    scientific: `Sigelus silens`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Asian Brown Flycatcher`,
    id: `asian_brown_flycatcher`,
    scientific: `Muscicapa dauurica`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: [],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `European Robin`,
    id: `european_robin`,
    scientific: `Erithacus rubecula`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `White-browed Robin-Chat`,
    id: `white-browed_robin-chat`,
    scientific: `Cossypha heuglini`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Cape Robin-Chat`,
    id: `cape_robin-chat`,
    scientific: `Dessonornis caffer`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Nightingale`,
    id: `common_nightingale`,
    scientific: `Luscinia megarhynchos`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Italy", "Turkey", "Hungary"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Robin`,
    id: `japanese_robin`,
    scientific: `Larvivora akahige`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Pied Flycatcher`,
    id: `european_pied_flycatcher`,
    scientific: `Ficedula hypoleuca`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Collared Flycatcher`,
    id: `collared_flycatcher`,
    scientific: `Ficedula albicollis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-flanked Bluetail`,
    id: `red-flanked_bluetail`,
    scientific: `Tarsiger cyanurus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black Redstart`,
    id: `black_redstart`,
    scientific: `Phoenicurus ochruros`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["Spain", "France", "Austria", "Switzerland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Redstart`,
    id: `common_redstart`,
    scientific: `Phoenicurus phoenicurus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["France"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Daurian Redstart`,
    id: `daurian_redstart`,
    scientific: `Phoenicurus auroreus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Blue Rock Thrush`,
    id: `blue_rock_thrush`,
    scientific: `Monticola solitarius`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Pied Bush Chat`,
    id: `pied_bush_chat`,
    scientific: `Saxicola caprata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR", "AU"],
    commonCountryNames: ["Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Stonechat`,
    id: `african_stonechat`,
    scientific: `Saxicola torquatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Madagascar Stonechat`,
    id: `madagascar_stonechat`,
    scientific: `Saxicola sibilla`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Stonechat`,
    id: `european_stonechat`,
    scientific: `Saxicola rubicola`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Northern Wheatear`,
    id: `northern_wheatear`,
    scientific: `Oenanthe oenanthe`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `American Dipper`,
    id: `american_dipper`,
    scientific: `Cinclus mexicanus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Midget Flowerpecker`,
    id: `midget_flowerpecker`,
    scientific: `Dicaeum aeneum`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Solomon Islands"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-chested Sunbird`,
    id: `red-chested_sunbird`,
    scientific: `Cinnyris erythrocercus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Purple Sunbird`,
    id: `purple_sunbird`,
    scientific: `Cinnyris asiaticus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Olive-backed Sunbird`,
    id: `olive-backed_sunbird`,
    scientific: `Cinnyris jugularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Malagasy Green Sunbird`,
    id: `malagasy_green_sunbird`,
    scientific: `Cinnyris notatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Rock Sparrow`,
    id: `rock_sparrow`,
    scientific: `Petronia petronia`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Cape Sparrow`,
    id: `cape_sparrow`,
    scientific: `Passer melanurus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Tree Sparrow`,
    id: `eurasian_tree_sparrow`,
    scientific: `Passer montanus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: [
      "China",
      "Japan",
      "Poland",
      "Russia",
      "Sweden",
      "Norway",
      "Netherlands",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Italian Sparrow`,
    id: `italian_sparrow`,
    scientific: `Passer italiae`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Italy"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `House Sparrow`,
    id: `house_sparrow`,
    scientific: `Passer domesticus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "OR", "AF"],
    commonCountryNames: [
      "United States",
      "Canada",
      "India",
      "Brazil",
      "Germany",
      "Mexico",
      "New Zealand",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "Argentina",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "Colombia",
      "Ecuador",
      "Peru",
      "Peru",
      "Malaysia",
      "Thailand",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL", "NA"],
    commonAll: true,
  },
  {
    name: `Southern Masked Weaver`,
    id: `southern_masked_weaver`,
    scientific: `Ploceus velatus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Tanzania"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Red-billed Quelea`,
    id: `red-billed_quelea`,
    scientific: `Quelea quelea`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: true,
  },
  {
    name: `Red Fody`,
    id: `red_fody`,
    scientific: `Foudia madagascariensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-winged Red Bishop`,
    id: `black-winged_red_bishop`,
    scientific: `Euplectes hordeaceus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Southern Red Bishop`,
    id: `southern_red_bishop`,
    scientific: `Euplectes orix`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Madagascar Mannikin`,
    id: `madagascar_mannikin`,
    scientific: `Lepidopygia nana`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Indian Silverbill`,
    id: `indian_silverbill`,
    scientific: `Euodice malabarica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["India"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Java Sparrow`,
    id: `java_sparrow`,
    scientific: `Padda oryzivora`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Scaly-breasted Munia`,
    id: `scaly-breasted_munia`,
    scientific: `Lonchura punctulata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR", "AU"],
    commonCountryNames: ["Malaysia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Javan Munia`,
    id: `javan_munia`,
    scientific: `Lonchura leucogastroides`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia", "Malaysia", "Thailand"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White-headed Munia`,
    id: `white-headed_munia`,
    scientific: `Lonchura maja`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["OR"],
    commonCountryNames: ["Indonesia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Blue-faced Parrotfinch`,
    id: `blue-faced_parrotfinch`,
    scientific: `Erythrura trichroa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AU"],
    commonCountryNames: ["Papua New Guinea"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Waxbill`,
    id: `common_waxbill`,
    scientific: `Estrilda astrild`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-billed Firefinch`,
    id: `red-billed_firefinch`,
    scientific: `Lagonosticta senegala`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Tanzania", "Kenya"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Firefinch`,
    id: `african_firefinch`,
    scientific: `Lagonosticta rubricata`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Dunnock`,
    id: `dunnock`,
    scientific: `Prunella modularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["New Zealand", "United Kingdom"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Western Yellow Wagtail`,
    id: `western_yellow_wagtail`,
    scientific: `Motacilla flava`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Russia"],
    commonRegionCodes: ["OR"],
    commonAll: false,
  },
  {
    name: `Madagascar Wagtail`,
    id: `madagascar_wagtail`,
    scientific: `Motacilla flaviventris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["AF"],
    commonCountryNames: ["Madagascar"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `White Wagtail`,
    id: `white_wagtail`,
    scientific: `Motacilla alba`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Finland",
      "Austria",
      "Switzerland",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `African Pied Wagtail`,
    id: `african_pied_wagtail`,
    scientific: `Motacilla aguimp`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["Uganda", "Zambia"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Japanese Wagtail`,
    id: `japanese_wagtail`,
    scientific: `Motacilla grandis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `African Pipit`,
    id: `african_pipit`,
    scientific: `Anthus cinnamomeus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: [],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Meadow Pipit`,
    id: `meadow_pipit`,
    scientific: `Anthus pratensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Ireland"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Tree Pipit`,
    id: `tree_pipit`,
    scientific: `Anthus trivialis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Russia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Chaffinch`,
    id: `common_chaffinch`,
    scientific: `Fringilla coelebs`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "New Zealand",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: true,
  },
  {
    name: `Chinese Grosbeak`,
    id: `chinese_grosbeak`,
    scientific: `Eophona migratoria`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Japanese Grosbeak`,
    id: `japanese_grosbeak`,
    scientific: `Eophona personata`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Eurasian Bullfinch`,
    id: `eurasian_bullfinch`,
    scientific: `Pyrrhula pyrrhula`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Sweden", "Norway", "Finland", "United Kingdom"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `House Finch`,
    id: `house_finch`,
    scientific: `Haemorhous mexicanus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada", "Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Greenfinch`,
    id: `european_greenfinch`,
    scientific: `Chloris chloris`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Grey-capped Greenfinch`,
    id: `grey-capped_greenfinch`,
    scientific: `Chloris sinica`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "OR"],
    commonCountryNames: ["China"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-fronted Canary`,
    id: `yellow-fronted_canary`,
    scientific: `Crithagra mozambica`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["AF"],
    commonCountryNames: ["South Africa", "Tanzania", "Kenya"],
    commonRegionCodes: ["AF"],
    commonAll: false,
  },
  {
    name: `Common Linnet`,
    id: `common_linnet`,
    scientific: `Linaria cannabina`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Ireland",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Switzerland",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Croatia",
      "Hungary",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Common Redpoll`,
    id: `common_redpoll`,
    scientific: `Acanthis flammea`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL", "NA"],
    commonCountryNames: ["Sweden", "Norway", "Finland", "United Kingdom"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `European Goldfinch`,
    id: `european_goldfinch`,
    scientific: `Carduelis carduelis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Germany",
      "Spain",
      "Ireland",
      "Greece",
      "Portugal",
      "Poland",
      "Russia",
      "France",
      "Sweden",
      "Norway",
      "Netherlands",
      "Italy",
      "Finland",
      "Austria",
      "Switzerland",
      "Denmark",
      "Czech Republic",
      "Belgium",
      "Estonia",
      "Ukraine",
      "Turkey",
      "Croatia",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `European Serin`,
    id: `european_serin`,
    scientific: `Serinus serinus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL", "AF"],
    commonCountryNames: ["Spain", "Greece", "Portugal", "Italy", "Austria"],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `American Goldfinch`,
    id: `american_goldfinch`,
    scientific: `Spinus tristis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Eurasian Siskin`,
    id: `eurasian_siskin`,
    scientific: `Spinus spinus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["PAL"],
    commonCountryNames: [
      "Greece",
      "Finland",
      "Turkey",
      "Hungary",
      "United Kingdom",
    ],
    commonRegionCodes: ["PAL"],
    commonAll: false,
  },
  {
    name: `Yellow-throated Euphonia`,
    id: `yellow-throated_euphonia`,
    scientific: `Euphonia hirundinacea`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Snow Bunting`,
    id: `snow_bunting`,
    scientific: `Plectrophenax nivalis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "PAL"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Corn Bunting`,
    id: `corn_bunting`,
    scientific: `Emberiza calandra`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Portugal"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellowhammer`,
    id: `yellowhammer`,
    scientific: `Emberiza citrinella`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Germany", "New Zealand", "Estonia"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Cretzschmar's Bunting`,
    id: `cretzschmar's_bunting`,
    scientific: `Emberiza caesia`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-headed Bunting`,
    id: `black-headed_bunting`,
    scientific: `Emberiza melanocephala`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Greece"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-faced Bunting`,
    id: `black-faced_bunting`,
    scientific: `Emberiza spodocephala`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Ochre-rumped Bunting`,
    id: `ochre-rumped_bunting`,
    scientific: `Emberiza yessoensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Japan"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Common Reed Bunting`,
    id: `common_reed_bunting`,
    scientific: `Emberiza schoeniclus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["PAL"],
    commonCountryNames: ["Germany", "Italy"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-chinned Sparrow`,
    id: `black-chinned_sparrow`,
    scientific: `Spizella atrogularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `American Tree Sparrow`,
    id: `american_tree_sparrow`,
    scientific: `Spizelloides arborea`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Dark-eyed Junco`,
    id: `dark-eyed_junco`,
    scientific: `Junco hyemalis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Rufous-collared Sparrow`,
    id: `rufous-collared_sparrow`,
    scientific: `Zonotrichia capensis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Brazil", "Argentina", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA", "SA"],
    commonAll: true,
  },
  {
    name: `White-throated Sparrow`,
    id: `white-throated_sparrow`,
    scientific: `Zonotrichia albicollis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: ["Canada"],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `Song Sparrow`,
    id: `song_sparrow`,
    scientific: `Melospiza melodia`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Red-breasted Meadowlark`,
    id: `red-breasted_meadowlark`,
    scientific: `Leistes militaris`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Montezuma Oropendola`,
    id: `montezuma_oropendola`,
    scientific: `Psarocolius montezuma`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Golden-winged Cacique`,
    id: `golden-winged_cacique`,
    scientific: `Cacicus chrysopterus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Yellow-rumped Cacique`,
    id: `yellow-rumped_cacique`,
    scientific: `Cacicus cela`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Altamira Oriole`,
    id: `altamira_oriole`,
    scientific: `Icterus gularis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Streak-backed Oriole`,
    id: `streak-backed_oriole`,
    scientific: `Icterus pustulatus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Baltimore Oriole`,
    id: `baltimore_oriole`,
    scientific: `Icterus galbula`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-vented Oriole`,
    id: `black-vented_oriole`,
    scientific: `Icterus wagleri`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-cowled Oriole`,
    id: `black-cowled_oriole`,
    scientific: `Icterus prosthemelas`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Red-winged Blackbird`,
    id: `red-winged_blackbird`,
    scientific: `Agelaius phoeniceus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada", "Mexico"],
    commonRegionCodes: ["NA"],
    commonAll: true,
  },
  {
    name: `Shiny Cowbird`,
    id: `shiny_cowbird`,
    scientific: `Molothrus bonariensis`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Melodious Blackbird`,
    id: `melodious_blackbird`,
    scientific: `Dives dives`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Common Grackle`,
    id: `common_grackle`,
    scientific: `Quiscalus quiscula`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA"],
    commonCountryNames: ["United States", "Canada", "Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Great-tailed Grackle`,
    id: `great-tailed_grackle`,
    scientific: `Quiscalus mexicanus`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: true,
  },
  {
    name: `Chestnut-capped Blackbird`,
    id: `chestnut-capped_blackbird`,
    scientific: `Chrysomus ruficapillus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Golden-winged Warbler`,
    id: `golden-winged_warbler`,
    scientific: `Vermivora chrysoptera`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Masked Yellowthroat`,
    id: `masked_yellowthroat`,
    scientific: `Geothlypis aequinoctialis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `American Redstart`,
    id: `american_redstart`,
    scientific: `Setophaga ruticilla`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA"],
    commonAll: false,
  },
  {
    name: `American Yellow Warbler`,
    id: `american_yellow_warbler`,
    scientific: `Setophaga aestiva`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["Mexico", "Colombia", "Ecuador", "Peru"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Mangrove Warbler`,
    id: `mangrove_warbler`,
    scientific: `Setophaga petechia`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA", "MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["NA", "MA"],
    commonAll: false,
  },
  {
    name: `Chestnut-sided Warbler`,
    id: `chestnut-sided_warbler`,
    scientific: `Setophaga pensylvanica`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["NA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Collared Whitestart`,
    id: `collared_whitestart`,
    scientific: `Myioborus torquatus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Northern Cardinal`,
    id: `northern_cardinal`,
    scientific: `Cardinalis cardinalis`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["NA", "MA"],
    commonCountryNames: ["United States", "Canada", "Mexico"],
    commonRegionCodes: ["NA"],
    commonAll: true,
  },
  {
    name: `Red-legged Honeycreeper`,
    id: `red-legged_honeycreeper`,
    scientific: `Cyanerpes cyaneus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Buff-throated Saltator`,
    id: `buff-throated_saltator`,
    scientific: `Saltator maximus`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Black-headed Saltator`,
    id: `black-headed_saltator`,
    scientific: `Saltator atriceps`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Golden-billed Saltator`,
    id: `golden-billed_saltator`,
    scientific: `Saltator aurantiirostris`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Bananaquit`,
    id: `bananaquit`,
    scientific: `Coereba flaveola`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Blue-black Grassquit`,
    id: `blue-black_grassquit`,
    scientific: `Volatinia jacarina`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Cinnamon-rumped Seedeater`,
    id: `cinnamon-rumped_seedeater`,
    scientific: `Sporophila torqueola`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["MA"],
    commonCountryNames: ["Mexico"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Black-and-rufous Warbling Finch`,
    id: `black-and-rufous_warbling_finch`,
    scientific: `Poospiza nigrorufa`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Saffron Finch`,
    id: `saffron_finch`,
    scientific: `Sicalis flaveola`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil", "Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: true,
  },
  {
    name: `Red-crested Cardinal`,
    id: `red-crested_cardinal`,
    scientific: `Paroaria coronata`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: ["SA"],
    commonAll: true,
  },
  {
    name: `Yellow-billed Cardinal`,
    id: `yellow-billed_cardinal`,
    scientific: `Paroaria capitata`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: ["Argentina"],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Red-capped Cardinal`,
    id: `red-capped_cardinal`,
    scientific: `Paroaria gularis`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["SA"],
    commonCountryNames: [],
    commonRegionCodes: ["SA"],
    commonAll: false,
  },
  {
    name: `Blue-gray Tanager`,
    id: `blue-gray_tanager`,
    scientific: `Thraupis episcopus`,
    commonCountry: true,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: ["Colombia", "Ecuador", "Peru"],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
  {
    name: `Sayaca Tanager`,
    id: `sayaca_tanager`,
    scientific: `Thraupis sayaca`,
    commonCountry: true,
    commonRegion: false,
    breedingRegion: ["SA"],
    commonCountryNames: ["Brazil"],
    commonRegionCodes: [],
    commonAll: false,
  },
  {
    name: `Golden-hooded Tanager`,
    id: `golden-hooded_tanager`,
    scientific: `Stilpnia larvata`,
    commonCountry: false,
    commonRegion: true,
    breedingRegion: ["MA", "SA"],
    commonCountryNames: [],
    commonRegionCodes: ["MA"],
    commonAll: false,
  },
];
